import React from 'react';
import styles from '../../styles/components/InputFeedback.module.scss';
import SuccessInputIcon from '../../assets/icons/grey-checkmark.svg';
import ErrorInputIcon from '../../assets/icons/error-input.svg';

interface InputFeedbackProps {
  message: string;
  variant: 'success' | 'error';
  extraClasses?: string;
}

const InputFeedback: React.FC<InputFeedbackProps> = ({ message, variant, extraClasses }) => {
  const icon = variant === 'success' ? SuccessInputIcon : ErrorInputIcon;

  return (
    <div className={`${styles.inputFeedback} ${extraClasses ? extraClasses : ''}`}>
      <img src={icon} alt={variant} />
      <p className={styles.feedbackText}>{message}</p>
    </div>
  );
};

export default InputFeedback;

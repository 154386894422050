// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountSettings_header__WUJvt {
  font-weight: 400;
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/AccountSettings.module.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,aAAA;AAAF","sourcesContent":["\n.header {\n  font-weight: 400;\n  margin-top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `AccountSettings_header__WUJvt`
};
export default ___CSS_LOADER_EXPORT___;

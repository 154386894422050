import { post, del } from './apiClient';
import { InvoiceRequest, InvoiceResponse } from '../types/purchase';


export const createInvoice = async (
  membershipData: InvoiceRequest
): Promise<InvoiceResponse> => {
  const response = await post<InvoiceResponse, InvoiceRequest>('/api/v1/user/invoices', membershipData);
  return response.data;
};

export const closeInvoice = async (invoiceId: string): Promise<InvoiceResponse> => {
  const response = await del<InvoiceResponse, null>(`/api/v1/user/invoices/${invoiceId}`);
  return response.data;
};
import MainMenu from "../components/MainMenu"

const GuestLayout: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  return (
    <div className="guest-layout">
      <MainMenu />
      <div className="container">
        {children}
      </div>
    </div>
  )
}

export default GuestLayout

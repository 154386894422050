import { Link, useSearchParams } from 'react-router-dom';
import styles from '../styles/pages/Dashboard.module.scss';
import { DashboardCard } from '../components/DashboardCard';
import MapPinIcon from '../assets/icons/map-pin.svg';
import CalendarIcon from '../assets/icons/calendar.svg';
import UserCircleIcon from '../assets/icons/user-circle.svg';
import QuestionMarkIcon from '../assets/icons/question-mark.svg';
import CopyIcon from '../assets/icons/copy-icon.svg'
import { useUpdateUserBoutique, useUserMemberships, useUserProfile } from '../hooks/useUserProfile';
import { useBoutique } from '../hooks/useBoutiques';
import { useToast } from '../components/core/ToastManager';
import { useQueryClient } from '@tanstack/react-query';
import GiftCardBalance from '../components/GiftCardBalance';
import Spinner from '../components/core/Spinner';
import MembershipCredits from '../components/core/MembershipCredits';
import { numberToText } from '../utils/caseConverter';

export const Dashboard = () => {
  // temp logic for UI testing while BE in development
  const [searchParams] = useSearchParams();
  const isFilled = searchParams.get('filled') === 'true';
  const appointmentDate = isFilled ? 'June 22, 2024, 1:00 PM' : '';
  const appointmentLocation = isFilled ? 'West Hollywood' : '';
  const appointmentTitle = isFilled ? 'The NOW, 80 Min, + 2 Enhancements' : 'You don’t have any upcoming bookings';
  const appointmentButton = isFilled ? 'View details' : 'Book an Appointment';
  const appointmentGuests = isFilled ? 2 : 0;
  // end temp vars & logic
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError } = useUserProfile()
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(userProfile?.data.boutiqueId || '')
  const { data: userMemberships, isLoading: isLoadingUserMemberships, error: userMembershipsError } = useUserMemberships(true);
  const membership = userMemberships?.membershipGroups && userMemberships?.membershipGroups.length > 0 ? userMemberships.membershipGroups[0].userMemberships[0].membership : null;
  const membershipGroup = userMemberships?.membershipGroups ? userMemberships.membershipGroups[0] : null;
  const membershipDescription = membership 
    ? `${numberToText(membership.frequencyPerMonth)} ${membership.durationInMinutes} minute ${membership.frequencyPerMonth > 1 ? 'massages' : 'massage'} per month` 
    : 'Enjoy a 50 or 80 minute massage, once to twice per month. Save on your monthly massages.';
  const membershipCredits = membership?.creditBalance || null
  const creditsName = membershipGroup?.name || null

  const updateBoutiqueMutation = useUpdateUserBoutique();

  const handleBoutiqueChange = async (boutiqueId: string) => {
    try {
      await updateBoutiqueMutation.mutateAsync(boutiqueId);
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
      queryClient.invalidateQueries({ queryKey: ['boutique', { boutique_id: userProfile?.data.boutiqueId }] });
      addToast('Boutique updated successfully', 'success');
    } catch (error) {
      addToast('Failed to update boutique. Please try again.', 'error');
    }
  };

  return (
    <div className={styles.dashboard}>
      <h1>Welcome back{userProfile?.data.firstName ? `, ${userProfile.data.firstName}!` : "!"}</h1>
      <div className={styles.cardGrid}>
        <DashboardCard iconUrl={CalendarIcon} title="Upcoming Appointment">
          <div className={styles.upcomingAppointmentContent}>
            <div className={styles.upcomingAppointmentInfo}>
              {appointmentDate && <Link to="/appointments" className={styles.viewAppointmentsButton}>VIEW ALL</Link>}
              {appointmentDate && <p className={styles.appointmentDate}>{appointmentDate}</p>}
              <p>{appointmentTitle}</p>
              {appointmentGuests > 0 && <p className={styles.appointmentGuests}>Me, {appointmentGuests} guests</p>}
              {appointmentLocation && <p className={styles.appointmentLocation}>{appointmentLocation}</p>}
            </div>
            <Link className={`button ${styles.cta}`} to="/appointments">
              {appointmentButton}
            </Link>
          </div>
        </DashboardCard>
        <DashboardCard iconUrl={UserCircleIcon} title={membership ? "Your Membership" : 'Become a Member'}>
          <div className={styles.memberCardContent}>
            {membershipGroup && <p className={styles.membershipSubtitle}>{membershipGroup.name}</p>}
            <p>{membershipDescription}</p>
            {membership && <p className={styles.membershipLocation}>{membership.boutique.name}</p>}
            {membershipCredits && creditsName && <MembershipCredits name={creditsName} amount={membershipCredits} />}
            <Link className={`button inverted ${styles.cta}`} to="/settings/memberships">
              {membership ? 'Manage Membership' : 'Become a Member'}
            </Link>
          </div>
        </DashboardCard>
        <DashboardCard 
          iconUrl={MapPinIcon}
          title="Your Boutique"
          locationPicker={true}
          currentBoutique={boutique?.data.id}
          onBoutiqueChange={handleBoutiqueChange}
          >
          {isLoadingBoutique ? <Spinner /> : (
            <div className={styles.boutiqueCardLocation}>
              <h4>{boutique?.data.name}</h4>
              <p>{boutique?.data.address}, {boutique?.data.city}, {boutique?.data.state} {boutique?.data.zip}</p>
            </div>
          )}
          {boutiqueError && <p className={styles.error}>Failed to load boutique information</p>}
        </DashboardCard>
        <DashboardCard iconUrl={CopyIcon} title="Gift Card Balance">
            <div className={styles.giftCardContent}>
              <p>Enter the gift card code to check the balance.</p>
              <GiftCardBalance />
              <Link className={`button__underline ${styles.purchaseGiftCardButton}`} to="/">
                Purchase Gift Card
              </Link>
            </div>
          </DashboardCard>
        {!isFilled && (
          <DashboardCard iconUrl={QuestionMarkIcon} title="In-Take Questionnaire">
            <div className={styles.questionnaireCardContent}>
              <p>Please complete the required in-take questionnaire prior to arriving for a massage.</p>
              <Link className={`button inverted ${styles.cta}`} to="/questionnaire">
                Complete Questionnaire
              </Link>
            </div>
          </DashboardCard>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createPaymentProfile, deletePaymentProfile } from '../api/paymentProfile';
import { DeletePaymentProfileResponse, PaymentProfileRequest, PaymentProfileResponse } from '../types/payment';
import { ApiErrorResponse } from '../types/api';

export const useCreatePaymentProfile = (
  onSuccess?: (data: PaymentProfileResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<PaymentProfileResponse, ApiErrorResponse, PaymentProfileRequest> => {
  return useMutation<PaymentProfileResponse, ApiErrorResponse, PaymentProfileRequest>({
    mutationFn: (paymentData: PaymentProfileRequest) => createPaymentProfile(paymentData),
    onSuccess,
    onError
  });
};

export const useDeletePaymentProfile = (
  onSuccess?: (data: DeletePaymentProfileResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<DeletePaymentProfileResponse, ApiErrorResponse, string> => {
  return useMutation<DeletePaymentProfileResponse, ApiErrorResponse, string>({
    mutationFn: (accountId: string) => deletePaymentProfile(accountId),
    onSuccess,
    onError
  });
};
export const countriesList = [
  {
      countryName: "Afghanistan",
      countryCode: "1",
      countryShortName: "AFG"
  },
  {
      countryName: "Albania",
      countryCode: "2",
      countryShortName: "ALB"
  },
  {
      countryName: "Algeria",
      countryCode: "3",
      countryShortName: "DZA"
  },
  {
      countryName: "American Samoa",
      countryCode: "4",
      countryShortName: "ASM"
  },
  {
      countryName: "Andorra",
      countryCode: "5",
      countryShortName: "AND"
  },
  {
      countryName: "Angola",
      countryCode: "6",
      countryShortName: "AGO"
  },
  {
      countryName: "Anguilla",
      countryCode: "7",
      countryShortName: "AIA"
  },
  {
      countryName: "Antarctica",
      countryCode: "8",
      countryShortName: "ATA"
  },
  {
      countryName: "Antigua and Barbuda",
      countryCode: "9",
      countryShortName: "ATG"
  },
  {
      countryName: "Argentina",
      countryCode: "10",
      countryShortName: "ARG"
  },
  {
      countryName: "Armenia",
      countryCode: "11",
      countryShortName: "ARM"
  },
  {
      countryName: "Aruba",
      countryCode: "12",
      countryShortName: "ABW"
  },
  {
      countryName: "Australia",
      countryCode: "13",
      countryShortName: "AUS"
  },
  {
      countryName: "Austria",
      countryCode: "14",
      countryShortName: "AUT"
  },
  {
      countryName: "Azerbaijan",
      countryCode: "15",
      countryShortName: "AZE"
  },
  {
      countryName: "Bahamas",
      countryCode: "16",
      countryShortName: "BHS"
  },
  {
      countryName: "Bahrain",
      countryCode: "17",
      countryShortName: "BHR"
  },
  {
      countryName: "Bangladesh",
      countryCode: "18",
      countryShortName: "BGD"
  },
  {
      countryName: "Barbados",
      countryCode: "19",
      countryShortName: "BRB"
  },
  {
      countryName: "Belarus",
      countryCode: "20",
      countryShortName: "BLR"
  },
  {
      countryName: "Belgium",
      countryCode: "21",
      countryShortName: "BEL"
  },
  {
      countryName: "Belize",
      countryCode: "22",
      countryShortName: "BLZ"
  },
  {
      countryName: "Benin",
      countryCode: "23",
      countryShortName: "BEN"
  },
  {
      countryName: "Bermuda",
      countryCode: "24",
      countryShortName: "BMU"
  },
  {
      countryName: "Bhutan",
      countryCode: "25",
      countryShortName: "BTN"
  },
  {
      countryName: "Bolivia",
      countryCode: "26",
      countryShortName: "BOL"
  },
  {
      countryName: "Bosnia and Herzegovina",
      countryCode: "27",
      countryShortName: "BIH"
  },
  {
      countryName: "Botswana",
      countryCode: "28",
      countryShortName: "BWA"
  },
  {
      countryName: "Brazil",
      countryCode: "29",
      countryShortName: "BRA"
  },
  {
      countryName: "British Indian Ocean Territory",
      countryCode: "30",
      countryShortName: "IOT"
  },
  {
      countryName: "British Virgin Islands",
      countryCode: "31",
      countryShortName: "VGB"
  },
  {
      countryName: "Brunei",
      countryCode: "32",
      countryShortName: "BRN"
  },
  {
      countryName: "Bulgaria",
      countryCode: "33",
      countryShortName: "BGR"
  },
  {
      countryName: "Burkina Faso",
      countryCode: "34",
      countryShortName: "BFA"
  },
  {
      countryName: "Burma (Myanmar)",
      countryCode: "35",
      countryShortName: "MMR"
  },
  {
      countryName: "Burundi",
      countryCode: "36",
      countryShortName: "BDI"
  },
  {
      countryName: "Cambodia",
      countryCode: "37",
      countryShortName: "KHM"
  },
  {
      countryName: "Cameroon",
      countryCode: "38",
      countryShortName: "CMR"
  },
  {
      countryName: "Canada",
      countryCode: "39",
      countryShortName: "CAN"
  },
  {
      countryName: "Cape Verde",
      countryCode: "40",
      countryShortName: "CPV"
  },
  {
      countryName: "Cayman Islands",
      countryCode: "41",
      countryShortName: "CYM"
  },
  {
      countryName: "Central African Republic",
      countryCode: "42",
      countryShortName: "CAF"
  },
  {
      countryName: "Chad",
      countryCode: "43",
      countryShortName: "TCD"
  },
  {
      countryName: "Chile",
      countryCode: "44",
      countryShortName: "CHL"
  },
  {
      countryName: "China",
      countryCode: "45",
      countryShortName: "CHN"
  },
  {
      countryName: "Christmas Island",
      countryCode: "46",
      countryShortName: "CXR"
  },
  {
      countryName: "Cocos (Keeling) Islands",
      countryCode: "47",
      countryShortName: "CCK"
  },
  {
      countryName: "Colombia",
      countryCode: "48",
      countryShortName: "COL"
  },
  {
      countryName: "Comoros",
      countryCode: "49",
      countryShortName: "COM"
  },
  {
      countryName: "Cook Islands",
      countryCode: "50",
      countryShortName: "COK"
  },
  {
      countryName: "Costa Rica",
      countryCode: "51",
      countryShortName: "CRC"
  },
  {
      countryName: "Croatia",
      countryCode: "52",
      countryShortName: "HRV"
  },
  {
      countryName: "Cuba",
      countryCode: "53",
      countryShortName: "CUB"
  },
  {
      countryName: "Cyprus",
      countryCode: "54",
      countryShortName: "CYP"
  },
  {
      countryName: "Czech Republic",
      countryCode: "55",
      countryShortName: "CZE"
  },
  {
      countryName: "Democratic Republic of the Congo",
      countryCode: "56",
      countryShortName: "COD"
  },
  {
      countryName: "Denmark",
      countryCode: "57",
      countryShortName: "DNK"
  },
  {
      countryName: "Djibouti",
      countryCode: "58",
      countryShortName: "DJI"
  },
  {
      countryName: "Dominica",
      countryCode: "59",
      countryShortName: "DMA"
  },
  {
      countryName: "Dominican Republic",
      countryCode: "60",
      countryShortName: "DOM"
  },
  {
      countryName: "Ecuador",
      countryCode: "61",
      countryShortName: "ECU"
  },
  {
      countryName: "Egypt",
      countryCode: "62",
      countryShortName: "EGY"
  },
  {
      countryName: "El Salvador",
      countryCode: "63",
      countryShortName: "SLV"
  },
  {
      countryName: "Equatorial Guinea",
      countryCode: "64",
      countryShortName: "GNQ"
  },
  {
      countryName: "Eritrea",
      countryCode: "65",
      countryShortName: "ERI"
  },
  {
      countryName: "Estonia",
      countryCode: "66",
      countryShortName: "EST"
  },
  {
      countryName: "Ethiopia",
      countryCode: "67",
      countryShortName: "ETH"
  },
  {
      countryName: "Falkland Islands",
      countryCode: "68",
      countryShortName: "FLK"
  },
  {
      countryName: "Faroe Islands",
      countryCode: "69",
      countryShortName: "FRO"
  },
  {
      countryName: "Fiji",
      countryCode: "70",
      countryShortName: "FJI"
  },
  {
      countryName: "Finland",
      countryCode: "71",
      countryShortName: "FIN"
  },
  {
      countryName: "France",
      countryCode: "72",
      countryShortName: "FRA"
  },
  {
      countryName: "French Polynesia",
      countryCode: "73",
      countryShortName: "PYF"
  },
  {
      countryName: "Gabon",
      countryCode: "74",
      countryShortName: "GAB"
  },
  {
      countryName: "Gambia",
      countryCode: "75",
      countryShortName: "GMB"
  },
  {
      countryName: "Gaza Strip",
      countryCode: "76",
      countryShortName: ""
  },
  {
      countryName: "Georgia",
      countryCode: "77",
      countryShortName: "GEO"
  },
  {
      countryName: "Germany",
      countryCode: "78",
      countryShortName: "DEU"
  },
  {
      countryName: "Ghana",
      countryCode: "79",
      countryShortName: "GHA"
  },
  {
      countryName: "Gibraltar",
      countryCode: "80",
      countryShortName: "GIB"
  },
  {
      countryName: "Greece",
      countryCode: "81",
      countryShortName: "GRC"
  },
  {
      countryName: "Greenland",
      countryCode: "82",
      countryShortName: "GRL"
  },
  {
      countryName: "Grenada",
      countryCode: "83",
      countryShortName: "GRD"
  },
  {
      countryName: "Guam",
      countryCode: "84",
      countryShortName: "GUM"
  },
  {
      countryName: "Guatemala",
      countryCode: "85",
      countryShortName: "GTM"
  },
  {
      countryName: "Guinea",
      countryCode: "86",
      countryShortName: "GIN"
  },
  {
      countryName: "Guinea-Bissau",
      countryCode: "87",
      countryShortName: "GNB"
  },
  {
      countryName: "Guyana",
      countryCode: "88",
      countryShortName: "GUY"
  },
  {
      countryName: "Haiti",
      countryCode: "89",
      countryShortName: "HTI"
  },
  {
      countryName: "Holy See (Vatican City)",
      countryCode: "90",
      countryShortName: "VAT"
  },
  {
      countryName: "Honduras",
      countryCode: "91",
      countryShortName: "HND"
  },
  {
      countryName: "Hong Kong",
      countryCode: "92",
      countryShortName: "HKG"
  },
  {
      countryName: "Hungary",
      countryCode: "93",
      countryShortName: "HUN"
  },
  {
      countryName: "Iceland",
      countryCode: "94",
      countryShortName: "IS"
  },
  {
      countryName: "India",
      countryCode: "95",
      countryShortName: "IND"
  },
  {
      countryName: "Indonesia",
      countryCode: "96",
      countryShortName: "IDN"
  },
  {
      countryName: "Iran",
      countryCode: "97",
      countryShortName: "IRN"
  },
  {
      countryName: "Iraq",
      countryCode: "98",
      countryShortName: "IRQ"
  },
  {
      countryName: "Ireland",
      countryCode: "99",
      countryShortName: "IRL"
  },
  {
      countryName: "Isle of Man",
      countryCode: "100",
      countryShortName: "IMN"
  },
  {
      countryName: "Israel",
      countryCode: "101",
      countryShortName: "ISR"
  },
  {
      countryName: "Italy",
      countryCode: "102",
      countryShortName: "ITA"
  },
  {
      countryName: "Ivory Coast",
      countryCode: "103",
      countryShortName: "CIV"
  },
  {
      countryName: "Jamaica",
      countryCode: "104",
      countryShortName: "JAM"
  },
  {
      countryName: "Japan",
      countryCode: "105",
      countryShortName: "JPN"
  },
  {
      countryName: "Jersey",
      countryCode: "106",
      countryShortName: "JEY"
  },
  {
      countryName: "Jordan",
      countryCode: "107",
      countryShortName: "JOR"
  },
  {
      countryName: "Kazakhstan",
      countryCode: "108",
      countryShortName: "KAZ"
  },
  {
      countryName: "Kenya",
      countryCode: "109",
      countryShortName: "KEN"
  },
  {
      countryName: "Kiribati",
      countryCode: "110",
      countryShortName: "KIR"
  },
  {
      countryName: "Kosovo",
      countryCode: "111",
      countryShortName: ""
  },
  {
      countryName: "Kuwait",
      countryCode: "112",
      countryShortName: "KWT"
  },
  {
      countryName: "Kyrgyzstan",
      countryCode: "113",
      countryShortName: "KGZ"
  },
  {
      countryName: "Lao People's Democratic Republic",
      countryCode: "114",
      countryShortName: "LAO"
  },
  {
      countryName: "Latvia",
      countryCode: "115",
      countryShortName: "LVA"
  },
  {
      countryName: "Lebanon",
      countryCode: "116",
      countryShortName: "LBN"
  },
  {
      countryName: "Lesotho",
      countryCode: "117",
      countryShortName: "LSO"
  },
  {
      countryName: "Liberia",
      countryCode: "118",
      countryShortName: "LBR"
  },
  {
      countryName: "Libya",
      countryCode: "119",
      countryShortName: "LBY"
  },
  {
      countryName: "Liechtenstein",
      countryCode: "120",
      countryShortName: "LIE"
  },
  {
      countryName: "Lithuania",
      countryCode: "121",
      countryShortName: "LTU"
  },
  {
      countryName: "Luxembourg",
      countryCode: "122",
      countryShortName: "LUX"
  },
  {
      countryName: "Macau",
      countryCode: "123",
      countryShortName: "MAC"
  },
  {
      countryName: "Macedonia",
      countryCode: "124",
      countryShortName: "MKD"
  },
  {
      countryName: "Madagascar",
      countryCode: "125",
      countryShortName: "MDG"
  },
  {
      countryName: "Malawi",
      countryCode: "126",
      countryShortName: "MWI"
  },
  {
      countryName: "Malaysia",
      countryCode: "127",
      countryShortName: "MYS"
  },
  {
      countryName: "Maldives",
      countryCode: "128",
      countryShortName: "MDV"
  },
  {
      countryName: "Mali",
      countryCode: "129",
      countryShortName: "MLI"
  },
  {
      countryName: "Malta",
      countryCode: "130",
      countryShortName: "MLT"
  },
  {
      countryName: "Marshall Islands",
      countryCode: "131",
      countryShortName: "MHL"
  },
  {
      countryName: "Mauritania",
      countryCode: "132",
      countryShortName: "MRT"
  },
  {
      countryName: "Mauritius",
      countryCode: "133",
      countryShortName: "MUS"
  },
  {
      countryName: "Mayotte",
      countryCode: "134",
      countryShortName: "MYT"
  },
  {
      countryName: "Mexico",
      countryCode: "135",
      countryShortName: "MEX"
  },
  {
      countryName: "Micronesia",
      countryCode: "136",
      countryShortName: "FSM"
  },
  {
      countryName: "Moldova",
      countryCode: "137",
      countryShortName: "MDA"
  },
  {
      countryName: "Monaco",
      countryCode: "138",
      countryShortName: "MCO"
  },
  {
      countryName: "Mongolia",
      countryCode: "139",
      countryShortName: "MNG"
  },
  {
      countryName: "Montenegro",
      countryCode: "140",
      countryShortName: "MNE"
  },
  {
      countryName: "Montserrat",
      countryCode: "141",
      countryShortName: "MSR"
  },
  {
      countryName: "Morocco",
      countryCode: "142",
      countryShortName: "MAR"
  },
  {
      countryName: "Mozambique",
      countryCode: "143",
      countryShortName: "MOZ"
  },
  {
      countryName: "Namibia",
      countryCode: "144",
      countryShortName: "NAM"
  },
  {
      countryName: "Nauru",
      countryCode: "145",
      countryShortName: "NRU"
  },
  {
      countryName: "Nepal",
      countryCode: "146",
      countryShortName: "NPL"
  },
  {
      countryName: "Netherlands",
      countryCode: "147",
      countryShortName: "NLD"
  },
  {
      countryName: "Netherlands Antilles",
      countryCode: "148",
      countryShortName: "ANT"
  },
  {
      countryName: "New Caledonia",
      countryCode: "149",
      countryShortName: "NCL"
  },
  {
      countryName: "New Zealand",
      countryCode: "150",
      countryShortName: "NZL"
  },
  {
      countryName: "Nicaragua",
      countryCode: "151",
      countryShortName: "NIC"
  },
  {
      countryName: "Niger",
      countryCode: "152",
      countryShortName: "NER"
  },
  {
      countryName: "Nigeria",
      countryCode: "153",
      countryShortName: "NGA"
  },
  {
      countryName: "Niue",
      countryCode: "154",
      countryShortName: "NIU"
  },
  {
      countryName: "Norfolk Island",
      countryCode: "155",
      countryShortName: "NFK"
  },
  {
      countryName: "North Korea",
      countryCode: "156",
      countryShortName: "PRK"
  },
  {
      countryName: "Northern Mariana Islands",
      countryCode: "157",
      countryShortName: "MNP"
  },
  {
      countryName: "Norway",
      countryCode: "158",
      countryShortName: "NOR"
  },
  {
      countryName: "Oman",
      countryCode: "159",
      countryShortName: "OMN"
  },
  {
      countryName: "Pakistan",
      countryCode: "160",
      countryShortName: "PAK"
  },
  {
      countryName: "Palau",
      countryCode: "161",
      countryShortName: "PLW"
  },
  {
      countryName: "Panama",
      countryCode: "162",
      countryShortName: "PAN"
  },
  {
      countryName: "Papua New Guinea",
      countryCode: "163",
      countryShortName: "PNG"
  },
  {
      countryName: "Paraguay",
      countryCode: "164",
      countryShortName: "PRY"
  },
  {
      countryName: "Peru",
      countryCode: "165",
      countryShortName: "PER"
  },
  {
      countryName: "Philippines",
      countryCode: "166",
      countryShortName: "PHL"
  },
  {
      countryName: "Pitcairn Islands",
      countryCode: "167",
      countryShortName: "PCN"
  },
  {
      countryName: "Poland",
      countryCode: "168",
      countryShortName: "POL"
  },
  {
      countryName: "Portugal",
      countryCode: "169",
      countryShortName: "PRT"
  },
  {
      countryName: "Puerto Rico",
      countryCode: "170",
      countryShortName: "PRI"
  },
  {
      countryName: "Qatar",
      countryCode: "171",
      countryShortName: "QAT"
  },
  {
      countryName: "Republic of the Congo",
      countryCode: "172",
      countryShortName: "COG"
  },
  {
      countryName: "Romania",
      countryCode: "173",
      countryShortName: "ROU"
  },
  {
      countryName: "Russia",
      countryCode: "174",
      countryShortName: "RUS"
  },
  {
      countryName: "Rwanda",
      countryCode: "175",
      countryShortName: "RWA"
  },
  {
      countryName: "Saint Barthelemy",
      countryCode: "176",
      countryShortName: "BLM"
  },
  {
      countryName: "Saint Helena",
      countryCode: "177",
      countryShortName: "SHN"
  },
  {
      countryName: "Saint Kitts and Nevis",
      countryCode: "178",
      countryShortName: "KNA"
  },
  {
      countryName: "Saint Lucia",
      countryCode: "179",
      countryShortName: "LCA"
  },
  {
      countryName: "Saint Martin",
      countryCode: "180",
      countryShortName: "MAF"
  },
  {
      countryName: "Saint Pierre and Miquelon",
      countryCode: "181",
      countryShortName: "SPM"
  },
  {
      countryName: "Saint Vincent and the Grenadines",
      countryCode: "182",
      countryShortName: "VCT"
  },
  {
      countryName: "Samoa",
      countryCode: "183",
      countryShortName: "WSM"
  },
  {
      countryName: "San Marino",
      countryCode: "184",
      countryShortName: "SMR"
  },
  {
      countryName: "Sao Tome and Principe",
      countryCode: "185",
      countryShortName: "STP"
  },
  {
      countryName: "Saudi Arabia",
      countryCode: "186",
      countryShortName: "SAU"
  },
  {
      countryName: "Senegal",
      countryCode: "187",
      countryShortName: "SEN"
  },
  {
      countryName: "Serbia",
      countryCode: "188",
      countryShortName: "SRB"
  },
  {
      countryName: "Seychelles",
      countryCode: "189",
      countryShortName: "SYC"
  },
  {
      countryName: "Sierra Leone",
      countryCode: "190",
      countryShortName: "SLE"
  },
  {
      countryName: "Singapore",
      countryCode: "191",
      countryShortName: "SGP"
  },
  {
      countryName: "Slovakia",
      countryCode: "192",
      countryShortName: "SVK"
  },
  {
      countryName: "Slovenia",
      countryCode: "193",
      countryShortName: "SVN"
  },
  {
      countryName: "Solomon Islands",
      countryCode: "194",
      countryShortName: "SLB"
  },
  {
      countryName: "Somalia",
      countryCode: "195",
      countryShortName: "SOM"
  },
  {
      countryName: "South Africa",
      countryCode: "196",
      countryShortName: "ZAF"
  },
  {
      countryName: "South Korea",
      countryCode: "197",
      countryShortName: "KOR"
  },
  {
      countryName: "Spain",
      countryCode: "198",
      countryShortName: "ESP"
  },
  {
      countryName: "Sri Lanka",
      countryCode: "199",
      countryShortName: "LKA"
  },
  {
      countryName: "Sudan",
      countryCode: "200",
      countryShortName: "SDN"
  },
  {
      countryName: "Suriname",
      countryCode: "201",
      countryShortName: "SUR"
  },
  {
      countryName: "Swaziland",
      countryCode: "203",
      countryShortName: "SWZ"
  },
  {
      countryName: "Sweden",
      countryCode: "204",
      countryShortName: "SWE"
  },
  {
      countryName: "Switzerland",
      countryCode: "205",
      countryShortName: "CHE"
  },
  {
      countryName: "Syrian Arab Republic",
      countryCode: "206",
      countryShortName: "SYR"
  },
  {
      countryName: "Taiwan",
      countryCode: "207",
      countryShortName: "TWN"
  },
  {
      countryName: "Tajikistan",
      countryCode: "208",
      countryShortName: "TJK"
  },
  {
      countryName: "Tanzania",
      countryCode: "209",
      countryShortName: "TZA"
  },
  {
      countryName: "Thailand",
      countryCode: "210",
      countryShortName: "THA"
  },
  {
      countryName: "Timor-Leste",
      countryCode: "211",
      countryShortName: "TLS"
  },
  {
      countryName: "Togo",
      countryCode: "212",
      countryShortName: "TGO"
  },
  {
      countryName: "Tokelau",
      countryCode: "213",
      countryShortName: "TKL"
  },
  {
      countryName: "Tonga",
      countryCode: "214",
      countryShortName: "TON"
  },
  {
      countryName: "Trinidad and Tobago",
      countryCode: "215",
      countryShortName: "TTO"
  },
  {
      countryName: "Tunisia",
      countryCode: "216",
      countryShortName: "TUN"
  },
  {
      countryName: "Turkey",
      countryCode: "217",
      countryShortName: "TUR"
  },
  {
      countryName: "Turkmenistan",
      countryCode: "218",
      countryShortName: "TKM"
  },
  {
      countryName: "Turks and Caicos Islands",
      countryCode: "219",
      countryShortName: "TCA"
  },
  {
      countryName: "Tuvalu",
      countryCode: "220",
      countryShortName: "TUV"
  },
  {
      countryName: "Uganda",
      countryCode: "221",
      countryShortName: "UGA"
  },
  {
      countryName: "Ukraine",
      countryCode: "222",
      countryShortName: "UKR"
  },
  {
      countryName: "United Arab Emirates",
      countryCode: "223",
      countryShortName: "ARE"
  },
  {
      countryName: "United Kingdom",
      countryCode: "224",
      countryShortName: "GBR"
  },
  {
      countryName: "United States",
      countryCode: "225",
      countryShortName: "USA"
  },
  {
      countryName: "Uruguay",
      countryCode: "226",
      countryShortName: "URY"
  },
  {
      countryName: "US Virgin Islands",
      countryCode: "227",
      countryShortName: "VIR"
  },
  {
      countryName: "Uzbekistan",
      countryCode: "228",
      countryShortName: "UZB"
  },
  {
      countryName: "Vanuatu",
      countryCode: "229",
      countryShortName: "VUT"
  },
  {
      countryName: "Venezuela",
      countryCode: "230",
      countryShortName: "VEN"
  },
  {
      countryName: "Vietnam",
      countryCode: "231",
      countryShortName: "VNM"
  },
  {
      countryName: "Wallis and Futuna",
      countryCode: "232",
      countryShortName: "WLF"
  },
  {
      countryName: "West Bank",
      countryCode: "233",
      countryShortName: ""
  },
  {
      countryName: "Western Sahara",
      countryCode: "234",
      countryShortName: "ESH"
  },
  {
      countryName: "Yemen",
      countryCode: "235",
      countryShortName: "YEM"
  },
  {
      countryName: "Zambia",
      countryCode: "236",
      countryShortName: "ZMB"
  },
  {
      countryName: "Zimbabwe",
      countryCode: "237",
      countryShortName: "ZWE"
  },
  {
      countryName: "Palestinian Territories",
      countryCode: "238",
      countryShortName: "PST"
  }
]

export function getCountryCodeByShortName(countryShortName: string){
    return countriesList.find(country => country.countryShortName.slice(0, 2) === countryShortName)?.countryCode
}
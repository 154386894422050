import { get, put } from './apiClient';
import { CreditCardsResponse, CreditCardResponse, UpdateBillingAddressRequest, UpdateBillingAddressResponse } from '../types/payment';

export const fetchCreditCards = async (): Promise<CreditCardsResponse> => {
  const response = await get<CreditCardsResponse>('api/v1/user/cards');
  return response.data;
};

export const fetchCreditCardById = async (id: string): Promise<CreditCardResponse> => {
  const response = await get<CreditCardResponse>(`api/v1/user/cards/${id}`);
  return response.data;
};

export const updateBillingAddress = async (
  id: string, 
  data: UpdateBillingAddressRequest
): Promise<UpdateBillingAddressResponse> => {
  const response = await put<UpdateBillingAddressResponse, UpdateBillingAddressRequest>(`/api/v1/user/cards/${id}`, data);
  return response.data;
};
import styles from '../styles/components/DashboardCard.module.scss'
import { LocationPicker } from './core/LocationPicker'

interface DashboardCardProps {
  iconUrl: string
  title: string
  locationPicker?: boolean
  currentBoutique?: string
  onBoutiqueChange?: (boutiqueId: string) => void
  children: React.ReactNode
}

export const DashboardCard:React.FC<DashboardCardProps> = ({
  iconUrl,
  title,
  locationPicker,
  currentBoutique,
  onBoutiqueChange,
  children
}) => {
  return (
    <div className={styles.dashboardCard}>
      <div>
        <img src={iconUrl} alt={title} />
        <div className={styles.headerContainer}>
          <h2>{title}</h2>
          {locationPicker && currentBoutique && onBoutiqueChange && (<LocationPicker currentBoutique={currentBoutique} onBoutiqueChange={onBoutiqueChange} />)}
        </div>
      </div>
        {children}
    </div>
  )
}

import { Outlet } from "react-router-dom"
import AccountSubMenu from "../components/AccountSubMenu"
import styles from '../styles/pages/AccountSettings.module.scss'

const links = [
  {
    label: 'Upcoming',
    to: '/appointments/upcoming',
  },
  {
    label: 'Past',
    to: '/appointments/past',
  }
]

export const AccountSettings = () => {
  return (
    <>
      <h1 className={styles.header}>Appointments</h1>
      <AccountSubMenu links={links} />
      <Outlet />
    </>
  )
}

export default AccountSettings
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from '../styles/components/AccountSubMenu.module.scss';

interface NavItemProps extends NavLinkProps {
  label: string;
  isHidden?: boolean;
  childLinks?: NavItemProps[];
}

interface props {
  links: NavItemProps[];
}

export default function AccountSubMenu({links}: props) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (activeLink) {
      activeLink.scrollIntoView({ block: 'nearest', inline: 'center' });
    }
  }, [activeLink, location.pathname]);

  return (
    <div className={styles.accountSubmenu}>
      <div className={styles.accountSubmenuContainer}>
        <nav>
          <ul>
            {links.map((link, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      [
                        styles.accountSubmenuLink,
                        isActive ? styles.active : '',
                        link.isHidden ? styles.hidden : ''
                      ].join(' ')
                    }
                    ref={(node) => {
                      if (node && node.classList.contains(styles.active)) {
                        setActiveLink(node);
                      }
                    }}
                  >
                    {link.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}

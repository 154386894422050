import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createInvoice, closeInvoice } from '../api/purchase'; 
import { InvoiceRequest, InvoiceResponse } from '../types/purchase'; 
import { ApiErrorResponse } from '../types/api'; 


export const useCreateInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, InvoiceRequest> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, InvoiceRequest>({
    mutationFn: (data: InvoiceRequest) => createInvoice(data),
    onSuccess, 
    onError, 
  });
};

export const useCloseInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, string> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, string>({
    mutationFn: (invoiceId: string) => closeInvoice(invoiceId),
    onSuccess,
    onError,
  });
};

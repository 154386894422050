import { useState } from "react"
import styles from '../styles/pages/NotificationsSettings.module.scss'
import { SwitchContainer } from "./core/SwitchContainer"
import WarningIcon from '../assets/icons/warning.svg'

export const NotificationsSettings = () => {
  const [showBanner, setShowBanner] = useState(true)
  const [isEmailOn, setIsEmailOn] = useState(false)
  const [isSmsOn, setIsSmsOn] = useState(false)
  const [isBrowserOn, setIsBrowserOn] = useState(false)


  const onEmailChange = () => {
    console.log('Email notifications:')
    setIsEmailOn(!isEmailOn)
  }

  const onSmsChange = () => {
    console.log('SMS notifications:')
    setIsSmsOn(!isSmsOn)
  }

  const onBrowserChange = () => {
    console.log('Browser notifications:')
    setIsBrowserOn(!isBrowserOn)
  }


  return (
    <div className={styles.notificationsSettings}>
      {showBanner && (
        <div className={styles.banner}>
          <div className={styles.bannerTitle}>
            <img src={WarningIcon} alt="Warning icon" />
            <p>Web push notifications are off.</p>
          </div>
          <p className={styles.bannerDescription}>Turn on notifications to get notified of new responses on your device.</p>
          <button className="button__link">Turn on notifications</button>
          <button className={styles.closeButton} onClick={() => setShowBanner(false)}>
            &#x2715;
          </button>
        </div>
      )}
      <div className={styles.notificationsGroup}>
        <h2>Notification One</h2>
        <p className={styles.notificationsDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
        <SwitchContainer
          id="email"
          isOn={isEmailOn}
          handleToggle={onEmailChange}
          label="Email"
          />
        <SwitchContainer
          id="sms"
          isOn={isSmsOn}
          handleToggle={onSmsChange}
          label="SMS"
          />
        <SwitchContainer
          id="browser"
          isOn={isBrowserOn}
          handleToggle={onBrowserChange}
          disabled
          label="Browser Notifications"
          description="Push notifications are off. To enable this feature, turn on notifications."
          />
      </div>
      <div className={styles.notificationsGroup}>
        <h2>Notification Two</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
        <SwitchContainer
          id="email"
          isOn={isEmailOn}
          handleToggle={onEmailChange}
          label="Email"
          />
        <SwitchContainer
          id="sms"
          isOn={isSmsOn}
          handleToggle={onSmsChange}
          label="SMS"
          />
        <SwitchContainer
          id="browser"
          isOn={isBrowserOn}
          handleToggle={onBrowserChange}
          disabled
          label="Browser Notifications"
          description="Push notifications are off. To enable this feature, turn on notifications."
          />
      </div>
    </div>
  )
}

export default NotificationsSettings
import styles from '../../styles/components/SwitchContainer.module.scss';
import Switch, { SwitchProps } from './Switch';

interface SwitchContainerProps extends SwitchProps {
  label: string;
  description?: string;
}

export const SwitchContainer: React.FC<SwitchContainerProps> = ({id, isOn, handleToggle, label, description, disabled }) => {
  return (
    <div className={styles.switch}>
      <div className={styles.switchContainer}>
        <div className={styles.switchInfo}>
          <p className={styles.switchTitle}>{label}</p>
        </div>
        <Switch
          id={id}
          isOn={isOn}
          handleToggle={handleToggle}
          disabled={disabled}
          />
      </div>
      <p className={styles.switchSubtitle}>{description}</p>
    </div>
  )
}

import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/pages/CreateAccount.module.scss';
import { Input } from '../components/core/Input';
import { AutoCompleteInput } from '../components/core/AutoCompleteInput';
import { Checkbox } from '../components/core/Checkbox';
import Modal from '../components/core/Modal';
import GearIcon from '../assets/icons/gear.svg';
import BookAppointmentIcon from '../assets/icons/book-appointment.svg';
import EyeIcon from '../assets/icons/eye.svg';
import { useMutation } from '@tanstack/react-query';
import { signUp } from '../api/auth';
import { useAuth } from '../components/auth/AuthProvider';
import { useToast } from '../components/core/ToastManager';
import { useBoutiques } from '../hooks/useBoutiques';
import { Link, useNavigate } from 'react-router-dom';
import { ApiErrorResponse } from '../types/api'

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  password: string;
  repeatPassword: string;
  boutique: string;
  terms: boolean;
}

const CreateAccount: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submittedData, setSubmittedData] = useState<FormData | null>(null);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { addToast } = useToast();

  const { data } = useBoutiques();

  const boutiques = data?.data.boutiques.map(boutique => ({
    value: boutique.id,
    label: boutique.name
  }));

  const schema: yup.ObjectSchema<FormData> = yup.object().shape({
    firstName: yup.string()
    .matches(/^[A-Za-z]+$/, 'First name should only contain letters')
    .max(32, 'First name cannot be longer than 32 characters')
    .required('First name is required'),
  lastName: yup.string()
    .matches(/^[A-Za-z]+$/, 'Last name should only contain letters')
    .max(32, 'Last name cannot be longer than 32 characters')
    .required('Last name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    mobileNumber: yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{10}$/, 'Mobile number is not valid'),
    password: yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain an uppercase letter')
      .matches(/[0-9]/, 'Password must contain a numeral')
      .matches(/[^a-zA-Z0-9]/, 'Password must contain a special character')
      .test('no-full-name', 'Password should not contain your full name', function (value) {
        const { firstName, lastName } = this.parent;
        const fullName = `${firstName}${lastName}`.toLowerCase();
        return !value?.toLowerCase().includes(fullName);
      }),
    repeatPassword: yup.string()
      .oneOf([yup.ref('password')], 'Passwords Do Not Match')
      .required('Please repeat your password'),
    boutique: yup.string().required('Please select a boutique').test(
      'is-valid-option',
      'Please select a valid option',
      function (value) {
        return boutiques?.some(option => option.value === value);
      }
    ),
    terms: yup.boolean().oneOf([true], 'You must agree to the terms').required('You must agree to the terms')
  });

  const { register, handleSubmit, control, formState: { errors, isValid }, setError } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const mutation = useMutation({
    mutationFn: (data: FormData) => signUp(data.email, data.firstName, data.lastName, data.boutique, data.password, data.mobileNumber),
    onSuccess: (data) => {
      login(data.headers);
      addToast('Successfully signed up', 'success');
      setIsModalOpen(true);
    },
    onError: (error: ApiErrorResponse) => {
      error.errors.forEach(error => {
        switch (error.key) {
          case 'email':
            setError('email', {
              type: 'manual',
              message: 'Email address already in use'
            })
            addToast('Email address already in use', 'error')
            break
          case 'mobile_phone':
            setError('mobileNumber', {
              type: 'manual',
              message: 'Phone number has already been taken'
            })
            addToast('Phone number has already been taken', 'error')
            break
          default:
            addToast('An error occurred during sign up', 'error')
        }
      })
    },
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    mutation.mutate(data);
    setSubmittedData(data);
  };

  const onSuccessModalClose = () => {
    setIsModalOpen(false);
    navigate('/dashboard');
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Create Your Account</h2>

        <Input id="firstName" label="First Name" inputProps={register('firstName')} error={errors.firstName?.message} />
        <Input id="lastName" label="Last Name" inputProps={register('lastName')} error={errors.lastName?.message} />
        <Input id="email" label="Email Address" inputProps={{...register('email'), autoComplete: 'username'}} error={errors.email?.message} />
        <Input id="mobileNumber" label="Mobile Number" inputProps={{...register('mobileNumber'), type: 'number'}} error={errors.mobileNumber?.message} />
        <Input id="password" label="Create Password" inputProps={{ ...register('password'), type: 'password', autoComplete: 'new-password'  }} error={errors.password?.message} />
        <Input id="repeatPassword" label="Repeat Your Password" inputProps={{...register('repeatPassword'), type: 'password', autoComplete: 'new-password'}} error={errors.repeatPassword?.message} />

        <Controller
          name="boutique"
          control={control}
          render={({ field }) => (
            <AutoCompleteInput
              id="boutique"
              label="Your Boutique"
              inputProps={field}
              error={errors.boutique?.message}
              options={boutiques || []}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        <Controller
          name="terms"
          control={control}
          render={({ field }) => (
            <Checkbox
              id="terms"
              checked={field.value}
              onChange={field.onChange}
              label={
                <span>
                  I agree to the <Link to="/terms">Terms of Use Agreement</Link> & <Link to="/privacy-policy">Privacy Policy</Link>
                </span>
              }
              error={errors.terms?.message}
            />
          )}
        />

        <button className={`button ${styles.button} ${!isValid ? "disabled" : ""}`} disabled={!isValid} type="submit">
          {mutation.isPending ? 'Creating Account...' : 'Create Account'}
        </button>
        {mutation.isError && (
          <p className={styles.errorMessage}>
            {mutation.error.error_messages.length ? mutation.error.error_messages[0] : 'An error occurred'}
          </p>
        )}
        <Link className={styles.haveAccountLink} to="/login">Have an account? Login</Link>
      </form>
      <Modal isOpen={isModalOpen} onClose={onSuccessModalClose}>
        <div className={styles.successModal}>
          <h2>You have successfully created your account!</h2>
          <p>Welcome to The NOW Massage app. Here you can:</p>
          <ul>
            <li><img src={BookAppointmentIcon} alt="booking icon" /> Book appointments</li>
            <li><img src={EyeIcon} alt="eye icon" /> View past appointments</li>
            <li><img src={GearIcon} alt="gear icon" /> Manage your membership</li>
          </ul>
          <button className="button" onClick={onSuccessModalClose}>Ok, got it</button>
        </div>
      </Modal>
    </>
  );
};

export default CreateAccount;

import { Outlet } from "react-router-dom"
import AccountSubMenu from "../components/AccountSubMenu"
import styles from '../styles/pages/AccountSettings.module.scss'

const links = [
  {
    label: 'Personal Details',
    to: '/settings/personal-details',
  },
  {
    label: 'Memberships',
    to: '/settings/memberships',
  },
  {
    label: 'Billing Details',
    to: '/settings/billing',
  },
  {
    label: 'Password',
    to: '/settings/password',
  },
  {
    label: 'Notifications',
    to: '/settings/notifications',
  }
];

export const AccountSettings = () => {
  return (
    <>
      <h1 className={styles.header}>Account Settings</h1>
      <AccountSubMenu links={links} />
      <Outlet />
    </>
  )
}

export default AccountSettings
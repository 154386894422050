import MainMenu from "../components/MainMenu"
import SubMenu from "../components/SubMenu"

const AuthenticatedLayout: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  return (
    <div className="authenticated-layout">
      <MainMenu />
      <SubMenu />
      <div className="container">
        {children}
      </div>
    </div>
  )
}

export default AuthenticatedLayout
import { get, put, del } from './apiClient';
import { MembershipByIdResponse, MembershipResponse } from '../types/memberships';
import { GenericSuccessResponse } from '../types/api';

export const fetchMemberships = async (
  boutiqueId?: string,
): Promise<MembershipResponse> => {

  const response = await get<MembershipResponse>(`/api/v1/boutiques/${boutiqueId}/memberships`);
  return response.data;
};

export const fetchMembershipById = async (id: string, boutiqueSlug: string): Promise<MembershipByIdResponse> => {
  const response = await get<MembershipByIdResponse>(`/api/v1/boutiques/${boutiqueSlug}/memberships/${id}`);
  
  return response.data;
};

export const freezeMembership = async (membershipId: string): Promise<GenericSuccessResponse> => {
  const response = await put<GenericSuccessResponse>(`/api/v1/user/memberships/${membershipId}/freeze`, {});
  
  return response.data;
}

export const unfreezeMembership = async (membershipId: string): Promise<GenericSuccessResponse> => {
  const response = await put<GenericSuccessResponse>(`/api/v1/user/memberships/${membershipId}/unfreeze`, {});
  
  return response.data;
}

// this endpoint cancels the membership immediately, use only for upgrade membership flow
export const cancelMembership = async (id: string): Promise<GenericSuccessResponse> => {
  const response = await del<GenericSuccessResponse, null>(`/api/v1/user/memberships/${id}`);
  
  return response.data;
};
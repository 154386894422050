import { useQuery } from '@tanstack/react-query';
import { fetchBoutiques, fetchBoutiqueById } from '../api/boutiques';
import { BoutiquesResponse, Boutique } from '../types/boutiques';
import { ApiErrorResponse } from '../types/api';


export const useBoutiques = (lat?: number, lng?: number, ip?: string) => {
  return useQuery<BoutiquesResponse, ApiErrorResponse>({
    queryKey: ['boutiques', lat, lng, ip],
    queryFn: () => fetchBoutiques(lat, lng, ip)
  });
};

export const useBoutique = (id: string) => {
  return useQuery<{ data: Boutique }, ApiErrorResponse>({
    queryKey: ['boutique', id],
    queryFn: () => fetchBoutiqueById(id)
  });
};

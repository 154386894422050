import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styles from '../styles/pages/UpdateMembership.module.scss';
import ActiveSelectIcon from '../assets/icons/active-select.svg';
import InactiveSelectIcon from '../assets/icons/inactive-select.svg';
import { useToast } from '../components/core/ToastManager';
import Modal from '../components/core/Modal';
import { DateTime } from 'luxon';
import { TermsModal } from '../components/TermsModal';
import { groupMembershipsByTitle } from '../utils/groupMemberships';

interface Membership {
  id: string;
  title: string;
  location: string;
  frequency: string;
  duration: string;
  price: string;
  nonMemberPrice: string;
  active: boolean;
}

const MOCK_MEMBERSHIPS: Membership[] = [
  { 
    id: "1",
    title: 'Sanctuary Ritual',
    location: 'West Hollywood, CA',
    frequency: 'Once per month',
    duration: '50 Minutes',
    price: '$80',
    nonMemberPrice: '$155',
    active: true,
  },
  { 
    id: "2",
    title: 'Sanctuary Ritual',
    location: 'West Hollywood, CA',
    frequency: 'Twice per month',
    duration: '50 Minutes',
    price: '$120',
    nonMemberPrice: '$195',
    active: false,
  },
  { 
    id: "3",
    title: 'Pampered Ritual',
    location: 'West Hollywood, CA',
    frequency: 'Once per month',
    duration: '80 Minutes',
    price: '$140',
    nonMemberPrice: '$195',
    active: false,
  },
  { 
    id: "4",
    title: 'Pampered Ritual',
    location: 'West Hollywood, CA',
    frequency: 'Twice per month',
    duration: '80 Minutes',
    price: '$220',
    nonMemberPrice: '$260',
    active: false,
  },
];

const priceToNumber = (price: string): number => parseFloat(price.replace('$', ''));

const UpdateMembership: React.FC = () => {
  // temp logic to simulate active membership UI state
  const [searchParams] = useSearchParams();
  const isFilled = searchParams.get('filled') === 'true';
  if (isFilled) {
    MOCK_MEMBERSHIPS[0].active = false;
    MOCK_MEMBERSHIPS[2].active = true;
  }

  const groupedMemberships = groupMembershipsByTitle(MOCK_MEMBERSHIPS);
  const [activeMembership, setActiveMembership] = useState<Membership | undefined>(
    MOCK_MEMBERSHIPS.find(membership => membership.active)
  );
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const currentActiveMembership = MOCK_MEMBERSHIPS.find(membership => membership.active);
  const currentActivePrice = currentActiveMembership ? priceToNumber(currentActiveMembership.price) : 0;

  const { addToast } = useToast();

  const handleMembershipClick = (membership: Membership) => {
    if ((priceToNumber(membership.price) >= currentActivePrice) && (membership.id !== activeMembership!.id)) {
      setActiveMembership(membership);
    } else if (membership.id === activeMembership!.id) {
      addToast('You are already on this membership', 'warning')
    } else {
      addToast('Contact your boutique directly to ask about downgrading your membership', 'warning');
    }
  }

  const isContinueButtonDisabled = activeMembership!.id === MOCK_MEMBERSHIPS.find(membership => membership.active)!.id;

  const handleContinue = () => {
    setOpenConfirmationModal(true);
  }

  return (
    <div className={styles.updateMembership}>
      <h1>Change Membership</h1>
      <p className={styles.location}>West Hollywood, CA</p>
      <div className={styles.membershipsContainer}>
        {Object.entries(groupedMemberships).map(([title, memberships]) => (
          <div key={title} className={styles.membershipColumn}>
            <h2>{memberships[0].duration}</h2>
            <p className={styles.membershipTitle}>{title}</p>
            {memberships.map((membership) => (
              <div
                key={membership.id}
                className={`${styles.membershipCard} ${membership.id === activeMembership!.id ? styles.active : ''} ${priceToNumber(membership.price) < currentActivePrice ? styles.disabledCard : ''}`}
                onClick={() => handleMembershipClick(membership)}
              >
                {membership.active && (
                  <div className={styles.currentMembership}>Current Membership</div>
                )}
                <div className={styles.membershipContentLeft}>
                  <img
                    className={styles.radioBox}
                    src={membership.id === activeMembership!.id ? ActiveSelectIcon : InactiveSelectIcon}
                    alt="radio box"
                  />
                  <div className={styles.membershipFrequency}>{membership.frequency}</div>
                </div>
                <div className={styles.membershipContentRight}>
                  <div className={styles.membershipPrice}>{membership.price}</div>
                  <div className={styles.nonMemberPrice}>Non-Members Pay {membership.nonMemberPrice}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.actionsContainer}>
        <div className={styles.actions}>
          <Link to="/settings/memberships" className={`button inverted ${styles.cancel}`}>Back</Link>
          <button onClick={handleContinue} disabled={isContinueButtonDisabled} className={`button ${styles.update}${isContinueButtonDisabled ? ' disabled' : ''}`}>Continue</button>
        </div>
      </div>

      <Modal isOpen={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
        <ConfirmationModal 
          currentMembership={currentActiveMembership} 
          newMembership={activeMembership} 
          onClose={() => setOpenConfirmationModal(false)}
          onTermsOpen={() => setIsTermsOpen(true)} 
        />
      </Modal>

      <TermsModal isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} />
    </div>
  );
};

interface ConfirmationModalProps {
  currentMembership?: Membership;
  newMembership?: Membership;
  onClose: () => void;
  onTermsOpen: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ currentMembership, newMembership, onClose, onTermsOpen }) => {
  const today = DateTime.local().toFormat('MM/d/yy');
  return (
    <div className={styles.confirmationModal}>
      <h2>Confirm Membership Change</h2>
      <div className={styles.modalMembershipCards}>
        <div className={styles.modalMembershipCardContainer}>
            <p className={styles.membershipType}>Current Membership:</p>
            <div className={styles.modalMembershipCard}>
              <div>
                <h3 className={styles.membershipCardTitle}>{currentMembership!.title}</h3>
                <p>{currentMembership!.duration} | {currentMembership!.frequency}</p>
                <p>{currentMembership!.location}</p>
              </div>
              <div>
                <div className={styles.membershipPrice}>{currentMembership!.price}</div>
              </div>
            </div>
        </div>
        <div className={styles.modalMembershipCardContainer}>
            <p className={styles.membershipType}>New Membership:</p>
            <div className={styles.modalMembershipCard}>
              <div>
                <h3 className={styles.membershipCardTitle}>{newMembership!.title}</h3>
                <p>{newMembership!.duration} | {newMembership!.frequency}</p>
                <p>{newMembership!.location}</p>
              </div>
              <div>
                <div className={styles.membershipPrice}>{newMembership!.price}</div>
              </div>
            </div>
        </div>
      </div>
      <p>Your new membership starts now. You’ll pay {newMembership!.price}/month starting {today}.</p>
      <button className={`button__link ${styles.termsLink}`} onClick={onTermsOpen}>You agree to the terms and conditions</button>
      <div className={styles.modalActions}>
        <button className={`button ${styles.confirm}`}>Confirm</button>
        <button onClick={onClose} className={`button__underline ${styles.cancel}`}>CANCEL</button>
      </div>
    </div>
  );
}

export default UpdateMembership;

import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/pages/Login.module.scss';
import { Input } from '../components/core/Input';
import { useAuth } from '../components/auth/AuthProvider';
import { signIn } from '../api/auth';
import { useToast } from '../components/core/ToastManager';
import { ApiErrorResponse } from '../types/api';

interface FormData {
  email: string;
  password: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup.string().required('Password is required')
});

const Login: React.FC = () => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const { login } = useAuth();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const mutation = useMutation({
    mutationFn: (data: FormData) => signIn(data.email, data.password),
    onSuccess: (data) => {
      login(data.headers);
      addToast('Successfully signed in', 'success');
      navigate('/dashboard');
    },
    onError: (error: ApiErrorResponse) => {
      addToast(error.error_messages[0] || 'An error occurred during sign in', 'error');
    },
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    mutation.mutate(data);
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginPageContent}>
        <img src="https://placehold.co/546x364" alt="Logo" />
        <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
        <p className={styles.contentCopy}>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.</p>
        <Link to="/" className="button inverted">Learn More</Link>
        <p className={styles.contentSubCopy}>Placerat duis ultricies lacus sed. Sem et tortor consequat id porta nibh. Morbi quis commodo odio aenean sed adipiscing diam donec. Eget arcu dictum varius duis. At urna condimentum mattis pellentesque id nibh tortor id aliquet. Id neque aliquam vestibulum morbi blandit cursus risus. Vulputate dignissim suspendisse in est ante in nibh mauris cursus. </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Sign in to your account</h2>
        
        <Input 
          id="email" 
          label="Email Address" 
          inputProps={{...register('email'), autoComplete: 'username'}} 
          error={errors.email?.message} 
        />
        <Input 
          id="password" 
          label="Password" 
          inputProps={{ 
            ...register('password'), 
            type: 'password', 
            autoComplete: 'current-password'  
          }} 
          error={errors.password?.message} 
        />
        <Link className={styles.forgotPasswordLink} to="/forgot-password">Forgot Password?</Link>
        <button 
          className={`button ${styles.buttonLogin} ${!isValid || mutation.isPending ? "disabled" : ""}`} 
          disabled={!isValid || mutation.isPending} 
          type="submit"
        >
          {mutation.isPending ? 'Signing in...' : 'Sign in'}
        </button>
        {mutation.isError && (
          <p className={styles.errorMessage}>
            {mutation.error instanceof Error ? mutation.error.message : 'An error occurred'}
          </p>
        )}
        <p className={styles.privacyPolicy}>
          By signing in, you agree to the Terms of Use and acknowledge the <Link to="/privacy-policy">Privacy Policy.</Link>
        </p>
        <div className={styles.separator}></div>
        <h3>New to NOW Massage?</h3>
        <Link className={`button inverted ${styles.createAccountLink}`} to="/register">Create an Account</Link>
      </form>
    </div>
  );
};

export default Login

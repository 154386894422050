import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/components/GiftCardBalance.module.scss';
import { Input } from '../components/core/Input';
import { useState } from 'react';

interface FormData {
  giftCardCode: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  giftCardCode: yup.string().required('Gift Card code is required'),
});

const GiftCardBalance: React.FC = () => {
  const { register, handleSubmit, formState: { errors, isValid }, setError, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const [showBalance, setShowBalance] = useState(false);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    console.log('gift card code is: ', data.giftCardCode);
    if (data.giftCardCode === '1234') {
      setShowBalance(true);
    } else {
      setShowBalance(false);
      setError('giftCardCode', {
        type: 'manual',
        message: 'Code is invalid, please try again.'
      });
    }
  };

  const checkAnotherCard = () => {
    setShowBalance(false);
    reset({ giftCardCode: '' });
  };

  if (showBalance) return (
    <div>
      <div className={styles.balance}>
        <p>Your card balance: $100.00</p>
      </div>
      <button onClick={checkAnotherCard} className={`button ${styles.button}`}>Check another card</button>
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Input
        id="giftCardCode"
        label="Enter Gift Card Code"
        inputProps={{ ...register('giftCardCode'), type: 'text', autoComplete: 'off' }}
        error={errors.giftCardCode?.message}
      />
      <button
        className={`button ${styles.button} ${!isValid ? "disabled" : ""}`}
        disabled={!isValid}
        type="submit"
      >
        Check Balance
      </button>
    </form>
  );
};

export default GiftCardBalance;

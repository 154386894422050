import { get } from './apiClient';
import { BoutiquesResponse, Boutique } from '../types/boutiques';

export const fetchBoutiques = async (
  lat?: number,
  lng?: number,
  ip?: string
): Promise<BoutiquesResponse> => {
  const params: Record<string, any> = {};
  if (lat !== undefined) params.lat = lat;
  if (lng !== undefined) params.lng = lng;
  if (ip !== undefined) params.ip = ip;

  const response = await get<BoutiquesResponse>('/api/v1/boutiques', { params });

  return response.data;
};

export const fetchBoutiqueById = async (id: string): Promise<{ data: Boutique }> => {
  const response = await get<{ data: Boutique }>(`/api/v1/boutiques/${id}`);
  
  return response.data;
};

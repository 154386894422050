import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchMemberships, fetchMembershipById, freezeMembership, unfreezeMembership, cancelMembership } from '../api/memberships';
import { MembershipByIdResponse, MembershipResponse } from '../types/memberships';
import { GenericSuccessResponse, ApiErrorResponse } from '../types/api';


export const useMemberships = (boutiqueId?: string) => {
  return useQuery<MembershipResponse, ApiErrorResponse>({
    queryKey: ['memberships', boutiqueId],
    queryFn: () => fetchMemberships(boutiqueId),
    enabled: !!boutiqueId,
  });
};

export const useMembershipById = (id: string, boutiqueSlug: string) => {
  return useQuery<MembershipByIdResponse, ApiErrorResponse>({
    queryKey: ['membership', id],
    queryFn: () => fetchMembershipById(id, boutiqueSlug),
    enabled: !!id, 
  });
};

export const useFreezeMembership = (
  onSuccess?: (data: GenericSuccessResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<GenericSuccessResponse, ApiErrorResponse, string>({
    mutationFn: (membershipId: string) => freezeMembership(membershipId),
    onSuccess,
    onError,
  });
};

export const useUnfreezeMembership = (
  onSuccess?: (data: GenericSuccessResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<GenericSuccessResponse, ApiErrorResponse, string>({
    mutationFn: (membershipId: string) => unfreezeMembership(membershipId),
    onSuccess,
    onError,
  });
};

export const useCancelMembership = (
  onSuccess?: (data: GenericSuccessResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<GenericSuccessResponse, ApiErrorResponse, string>({
    mutationFn: (membershipId: string) => cancelMembership(membershipId),
    onSuccess,
    onError,
  });
};

import { SelectHTMLAttributes } from 'react';
import styles from '../../styles/components/Select.module.scss';

interface SelectProps {
  id: string;
  label: string;
  className?: string;
  selectProps: SelectHTMLAttributes<HTMLSelectElement>;
  children: React.ReactNode;
  error?: string;
}

export const Select: React.FC<SelectProps> = ({ id, label, className, selectProps, children, error }) => {
  return (
    <div className={`${styles.select} ${className} ${error ? styles.entryError : ''}`}>
      <select
        id={id}
        {...selectProps}
      >
        <option value="" disabled hidden>{label}</option>
        {children}
      </select>
      <label htmlFor={id}>{label}</label>
      <span className={styles.underline}></span>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

const snakeToCamel = (str: string): string =>
  str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());

const isObject = (obj: unknown): obj is Record<string, unknown> =>
  obj !== null && typeof obj === 'object' && !Array.isArray(obj);

export const convertKeysToCamelCase = <T>(obj: T): T => {
  if (isObject(obj)) {
    const newObj: Record<string, unknown> = {};
    Object.keys(obj).forEach((key) => {
      newObj[snakeToCamel(key)] = convertKeysToCamelCase((obj as Record<string, unknown>)[key]);
    });
    return newObj as T;
  } else if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item)) as unknown as T;
  }
  return obj;
};

const camelToSnake = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const convertKeysToSnakeCase = <T>(obj: T): T => {
  if (isObject(obj)) {
    const newObj: Record<string, unknown> = {};
    Object.keys(obj).forEach((key) => {
      newObj[camelToSnake(key)] = convertKeysToSnakeCase((obj as Record<string, unknown>)[key]);
    });
    return newObj as T;
  } else if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item)) as unknown as T;
  }
  return obj;
};

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from '../utils/caseConverter';
import { ApiErrorResponse } from '../types/api';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authHeaders = localStorage.getItem('auth');
    const token = authHeaders ? JSON.parse(authHeaders).authorization : null;
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    response.data = convertKeysToCamelCase(response.data);
    return response;
  },
  (error) => {
    const urlExceptions = ['/api/v1/users/sign_in', '/api/v1/users/password'];
    if (error.response && error.response.status === 401 && !urlExceptions.includes(error.config.url)) {
      localStorage.removeItem('auth');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const apiClient = async <TResponse, TRequest = undefined>(
  method: HttpMethod,
  url: string,
  data?: TRequest,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<TResponse>> => {
  try {
    const snakeCaseData = (method === 'POST' || method === 'PUT' || method === 'DELETE') 
    ? convertKeysToSnakeCase(data)
    : data;

  const response: AxiosResponse<TResponse> = await axiosInstance({
    method: method as Method,
    url,
    data: snakeCaseData,
    ...config,
  });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const apiError = error.response?.data as ApiErrorResponse;
      throw apiError || new Error('An error occurred');
    }
    throw error;
  }
};

// Update convenience methods
export const get = <TResponse>(url: string, config?: AxiosRequestConfig) =>
  apiClient<TResponse, undefined>('GET', url, undefined, config);

export const post = <TResponse, TRequest>(url: string, data: TRequest, config?: AxiosRequestConfig) =>
  apiClient<TResponse, TRequest>('POST', url, data, config);

export const put = <TResponse, TRequest>(url: string, data: TRequest, config?: AxiosRequestConfig) =>
  apiClient<TResponse, TRequest>('PUT', url, data, config);

export const del = <TResponse, TRequest>(url: string, data?: TRequest, config?: AxiosRequestConfig) =>
  apiClient<TResponse, TRequest>('DELETE', url, data, config);

export default apiClient;

import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { fetchCreditCardById, fetchCreditCards, updateBillingAddress } from '../api/creditCards';
import { CreditCardResponse, CreditCardsResponse, UpdateBillingAddressRequest, UpdateBillingAddressResponse } from '../types/payment';
import { ApiErrorResponse } from '../types/api';

export const useCreditCards = () => {
  return useQuery<CreditCardsResponse, ApiErrorResponse>({
    queryKey: ['creditCards'],
    queryFn: () => fetchCreditCards()
  });
};

export const useCreditCardById = (id: string) => {
  return useQuery<CreditCardResponse, ApiErrorResponse>({
    queryKey: ['creditCard', id], // Different query key based on the id
    queryFn: () => fetchCreditCardById(id),
    enabled: !!id, // Prevent fetching if id is not provided
  });
};

export const useUpdateBillingAddress = (
  onSuccess?: (data: UpdateBillingAddressResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<UpdateBillingAddressResponse, ApiErrorResponse, { id: string, data: UpdateBillingAddressRequest }> => {
  return useMutation<UpdateBillingAddressResponse, ApiErrorResponse, { id: string, data: UpdateBillingAddressRequest }>({
    mutationFn: ({ id, data }) => updateBillingAddress(id, data),
    onSuccess,
    onError
  });
};
import { useState } from 'react';
import InfoIcon from '../../assets/icons/info.svg';
import styles from '../../styles/components/MembershipCredits.module.scss';
import Modal from './Modal';

interface props {
  membershipCredits: {
    title: string;
    amount: number;
  }[];
}

export const MembershipCredits:React.FC<props> = ({ membershipCredits }) => {
  const [showCreditsInfoModal, setShowCreditsInfoModal] = useState(false);
  return (
    <div className={styles.membershipCredits}>
      {membershipCredits.map((credit, index) => (
        <div
          className={`${styles.membershipCreditsItem} ${credit.amount > 0 ? styles.filled : ''}`}
          key={index}
        >
          <p>
            {credit.title}
            <span>{credit.amount}</span>
          </p>
          <button className={styles.creditsInfoButton} onClick={() => setShowCreditsInfoModal(true)}>
            <img src={InfoIcon} alt="Info Icon" />
          </button>
        </div>
      ))}
      <Modal
        isOpen={showCreditsInfoModal}
        onClose={() => setShowCreditsInfoModal(false)}
        className={styles.creditsModal}
      >
        <div className={styles.creditsModalContent}>
          <h2 className={styles.creditsModalTitle}>Ritual Membership Credits</h2>
          <ul>
            <li>Your membership credit balance includes all upcoming appointments. Credits are deducted when your booking is made.</li>
            <li>Should you cancel your appointment 12 hours before your appointment, any unused credits will be reflected in your available credits.</li>
            <li>Should you cancel your appointment within 12 hours, your credits will be returned to the balance.</li>
          </ul>
          <button className={`button large inverted ${styles.creditsModalButton}`} onClick={() => setShowCreditsInfoModal(false)}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default MembershipCredits
import Modal from './core/Modal'
import styles from '../styles/components/TermsModal.module.scss'

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsModal:React.FC<TermsModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal 
      isOpen={isOpen}
      onClose={onClose}
      className={styles.termsModalIndex}
    >
     <div className={styles.termsModalContent}>
       <h2 className={styles.termsModalTitle}>Membership Agreement</h2>
       <p>This Membership Agreement includes an automatic renewal clause and an arbitration provision with a class action waiver applicable to all members. Please read this Membership Agreement carefully since it forms a legally-binding contract.
       By signing your acceptance below, Member agrees to purchase the membership program at the price described herein and be bound by the terms and conditions as described in the Membership Agreement, which incorporates by reference The NOW’s Terms of Service, Privacy Policy and any later-signed Addenda. All of these documents, taken together, form the “Agreement” between the parties identified below.
       </p>
       <button className={`button__link ${styles.termsModalButton}`} onClick={onClose}>
         Close
       </button>
     </div>
   </Modal>
  )
}

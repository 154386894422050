import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/pages/CancelMembership.module.scss'
import ArrowDownIcon from '../assets/icons/arrow-down.svg';
import Modal from '../components/core/Modal';

export const CancelMembership = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  return (
    <div className={styles.cancelMembership}>
      <Link to="/settings/memberships" className={styles.backButton}>
        <img src={ArrowDownIcon} alt="Arrow Down Icon" />
        Back
      </Link>
      <h1>Cancel Membership</h1>
      <div className={styles.cards}>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Freeze your membership for up to 3 months in a calendar year.</h2>
            <p>You can use any previously accrued credits during the freeze, however, you will not accumulate additional credits.</p>
          </div>
          <button className={`button inverted ${styles.button}`}>Pause membership</button>
        </div>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Change to 50 minute massage once per week</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
          </div>
          <Link to='/settings/memberships/update/128393' className={`button ${styles.button}`}>Change membership</Link>
        </div>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Cancel My Membership</h2>
            <p>Per our Terms of Use, you will be charged one more billing cycle at the rate of $120.  You will have access to your membership until the end of your billing period on 7/12/24.</p>
          </div>
          <button onClick={() => setShowCancelModal(true)} className={`button inverted ${styles.button} ${styles.cancelButton}`}>Cancel membership</button>
        </div>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Decided not to cancel?</h2>
            <p>Instead of canceling, take a break for 1 month. You can still browse, shop and unpause anytime.</p>
          </div>
          <Link to='/dashboard' className={`button ${styles.button}`}>Back to dashboard</Link>
        </div>

      </div>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        className={styles.cancelModal}
        >
          <h2>Are you sure you want to cancel your membership?</h2>
          <ul>
            <li>Your last billing cycle will be charged on x/xx/xxxx for $xxx</li>
            <li>You will have access to your membership until the end of your billing period on 7/12/24</li>
            <li>X credits will be valid for X months after your membership is cancelled</li>
          </ul>
          <button className={`button inverted ${styles.cancelButton}`}>Yes, Cancel Membership</button>
          <button className={`button__underline ${styles.closeButton}`} onClick={() => setShowCancelModal(false)}>No, Keep Membership</button>
        </Modal>
    </div>
  )
}

export default CancelMembership;
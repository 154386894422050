import { useQuery, useMutation, UseMutationResult } from '@tanstack/react-query';
import { fetchUserProfile, updateUserPassword, updateUserProfile, updateUserBoutique, triggerVerificationEmail, resetUserPassword  } from '../api/user';
import { UserProfileResponse, UserProfileUpdateRequest, UserProfileUpdateResponse, UpdatePasswordData, UserProfile, TriggerVerificationEmailRequest, TriggerVerificationEmailResponse, ResetPasswordRequest, ResetPasswordResponse } from '../types/user';
import { ApiErrorResponse } from '../types/api';

export const useUserProfile = () => {
  return useQuery<UserProfileResponse, ApiErrorResponse>({
    queryKey: ['userProfile'],
    queryFn: fetchUserProfile,
  });
};

export const useUpdateUserProfile = (): UseMutationResult<UserProfileUpdateResponse, ApiErrorResponse, UserProfileUpdateRequest> => {
  return useMutation<UserProfileUpdateResponse, ApiErrorResponse, UserProfileUpdateRequest>({
    mutationFn: (user: UserProfileUpdateRequest) => updateUserProfile(user)
  });
};

export const useUpdateUserPassword = (): UseMutationResult<void, ApiErrorResponse, UpdatePasswordData> => {
  return useMutation<void, ApiErrorResponse, UpdatePasswordData>({
    mutationFn: (data: UpdatePasswordData) => updateUserPassword({
      old_password: data.oldPassword,
      new_password: data.newPassword,
    })
  });
};

export const useUpdateUserBoutique = (): UseMutationResult<UserProfile, ApiErrorResponse, string> => {
  return useMutation<UserProfile, ApiErrorResponse, string>({
    mutationFn: (boutiqueId: string) => updateUserBoutique(boutiqueId)
  });
};

export const useTriggerVerificationEmail = (
  onSuccess?: (data: TriggerVerificationEmailResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<TriggerVerificationEmailResponse, ApiErrorResponse, TriggerVerificationEmailRequest> => {
  return useMutation<TriggerVerificationEmailResponse, ApiErrorResponse, TriggerVerificationEmailRequest>({
    mutationFn: (data: TriggerVerificationEmailRequest) => triggerVerificationEmail(data),
    onSuccess,
    onError,
  });
};

export const useResetUserPassword = (onSuccess?: () => void, onError?: (error: ApiErrorResponse) => void) => {
  return useMutation<ResetPasswordResponse, ApiErrorResponse, ResetPasswordRequest>({
    mutationFn: (data: ResetPasswordRequest) => resetUserPassword(data),
    onSuccess,
    onError,
  });
};
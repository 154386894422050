interface Membership {
  id: string;
  title: string;
  location: string;
  frequency: string;
  duration: string;
  price: string;
  nonMemberPrice: string;
  active: boolean;
}

interface GroupedMemberships {
  [key: string]: Membership[];
}

export const groupMembershipsByTitle = (memberships: Membership[]): GroupedMemberships => {
  return memberships.reduce((acc: GroupedMemberships, membership: Membership) => {
    const { title } = membership;
    if (!acc[title]) {
      acc[title] = [];
    }
    acc[title].push(membership);
    return acc;
  }, {});
};
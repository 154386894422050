// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/IvyPresto/IvyPresto Display Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Neutra2Text/Neutra2Text-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "headfont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "basefont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "headfont", sans-serif;
  font-weight: 400;
}

.GiftCardBalance_balance__s-Pq\\+ {
  border: 1px solid #d8d9d9;
  padding: 1.1rem 1rem;
  margin-bottom: 1rem;
}
.GiftCardBalance_balance__s-Pq\\+ p {
  margin: 0;
}

.GiftCardBalance_form__HVFck {
  margin-top: 0.5rem;
}

.GiftCardBalance_button__A9tPp {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/constants/fonts.scss","webpack://./src/styles/components/GiftCardBalance.module.scss","webpack://./src/styles/constants/typography.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;ACCF;ADEA;EACE,uBAAA;EACA,+DAAA;ACAF;ACPA;EACC,mCAAA;EACA,gBAAA;ADSD;;AATA;EACE,yBAAA;EACA,oBAAA;EACA,mBAAA;AAYF;AAVE;EACE,SAAA;AAYJ;;AATA;EACE,kBAAA;AAYF;;AATA;EACE,WAAA;AAYF","sourcesContent":["@font-face {\n  font-family: 'headfont';\n  src: url('/assets/fonts/IvyPresto/IvyPresto Display Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'basefont';\n  src: url('/assets/fonts/Neutra2Text/Neutra2Text-Book.otf') format('opentype');\n}\n","@import '../constants/';\n\n.balance {\n  border: 1px solid $grey;\n  padding: 1.1rem 1rem;\n  margin-bottom: 1rem;\n\n  p {\n    margin: 0;\n  }\n}\n.form {\n  margin-top: .5rem;\n}\n\n.button {\n  width: 100%;\n}","h1, h2, h3, h4, h5, h6 {\n font-family: 'headfont', sans-serif;\n font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance": `GiftCardBalance_balance__s-Pq+`,
	"form": `GiftCardBalance_form__HVFck`,
	"button": `GiftCardBalance_button__A9tPp`
};
export default ___CSS_LOADER_EXPORT___;

interface Option {
  value: string;
  label: string;
}

export const USStates: Option[] = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

export const countries: Option[] = [
  { label: "Afghanistan", value: "1" },
  { label: "Albania", value: "2" },
  { label: "Algeria", value: "3" },
  { label: "American Samoa", value: "4" },
  { label: "Andorra", value: "5" },
  { label: "Angola", value: "6" },
  { label: "Anguilla", value: "7" },
  { label: "Antarctica", value: "8" },
  { label: "Antigua and Barbuda", value: "9" },
  { label: "Argentina", value: "10" },
  { label: "Armenia", value: "11" },
  { label: "Aruba", value: "12" },
  { label: "Australia", value: "13" },
  { label: "Austria", value: "14" },
  { label: "Azerbaijan", value: "15" },
  { label: "Bahamas", value: "16" },
  { label: "Bahrain", value: "17" },
  { label: "Bangladesh", value: "18" },
  { label: "Barbados", value: "19" },
  { label: "Belarus", value: "20" },
  { label: "Belgium", value: "21" },
  { label: "Belize", value: "22" },
  { label: "Benin", value: "23" },
  { label: "Bermuda", value: "24" },
  { label: "Bhutan", value: "25" },
  { label: "Bolivia", value: "26" },
  { label: "Bosnia and Herzegovina", value: "27" },
  { label: "Botswana", value: "28" },
  { label: "Brazil", value: "29" },
  { label: "British Indian Ocean Territory", value: "30" },
  { label: "British Virgin Islands", value: "31" },
  { label: "Brunei", value: "32" },
  { label: "Bulgaria", value: "33" },
  { label: "Burkina Faso", value: "34" },
  { label: "Burma (Myanmar)", value: "35" },
  { label: "Burundi", value: "36" },
  { label: "Cambodia", value: "37" },
  { label: "Cameroon", value: "38" },
  { label: "Canada", value: "39" },
  { label: "Cape Verde", value: "40" },
  { label: "Cayman Islands", value: "41" },
  { label: "Central African Republic", value: "42" },
  { label: "Chad", value: "43" },
  { label: "Chile", value: "44" },
  { label: "China", value: "45" },
  { label: "Christmas Island", value: "46" },
  { label: "Cocos (Keeling) Islands", value: "47" },
  { label: "Colombia", value: "48" },
  { label: "Comoros", value: "49" },
  { label: "Cook Islands", value: "50" },
  { label: "Costa Rica", value: "51" },
  { label: "Croatia", value: "52" },
  { label: "Cuba", value: "53" },
  { label: "Cyprus", value: "54" },
  { label: "Czech Republic", value: "55" },
  { label: "Democratic Republic of the Congo", value: "56" },
  { label: "Denmark", value: "57" },
  { label: "Djibouti", value: "58" },
  { label: "Dominica", value: "59" },
  { label: "Dominican Republic", value: "60" },
  { label: "Ecuador", value: "61" },
  { label: "Egypt", value: "62" },
  { label: "El Salvador", value: "63" },
  { label: "Equatorial Guinea", value: "64" },
  { label: "Eritrea", value: "65" },
  { label: "Estonia", value: "66" },
  { label: "Ethiopia", value: "67" },
  { label: "Falkland Islands", value: "68" },
  { label: "Faroe Islands", value: "69" },
  { label: "Fiji", value: "70" },
  { label: "Finland", value: "71" },
  { label: "France", value: "72" },
  { label: "French Polynesia", value: "73" },
  { label: "Gabon", value: "74" },
  { label: "Gambia", value: "75" },
  { label: "Gaza Strip", value: "76" },
  { label: "Georgia", value: "77" },
  { label: "Germany", value: "78" },
  { label: "Ghana", value: "79" },
  { label: "Gibraltar", value: "80" },
  { label: "Greece", value: "81" },
  { label: "Greenland", value: "82" },
  { label: "Grenada", value: "83" },
  { label: "Guam", value: "84" },
  { label: "Guatemala", value: "85" },
  { label: "Guinea", value: "86" },
  { label: "Guinea-Bissau", value: "87" },
  { label: "Guyana", value: "88" },
  { label: "Haiti", value: "89" },
  { label: "Holy See (Vatican City)", value: "90" },
  { label: "Honduras", value: "91" },
  { label: "Hong Kong", value: "92" },
  { label: "Hungary", value: "93" },
  { label: "Iceland", value: "94" },
  { label: "India", value: "95" },
  { label: "Indonesia", value: "96" },
  { label: "Iran", value: "97" },
  { label: "Iraq", value: "98" },
  { label: "Ireland", value: "99" },
  { label: "Isle of Man", value: "100" },
  { label: "Israel", value: "101" },
  { label: "Italy", value: "102" },
  { label: "Ivory Coast", value: "103" },
  { label: "Jamaica", value: "104" },
  { label: "Japan", value: "105" },
  { label: "Jersey", value: "106" },
  { label: "Jordan", value: "107" },
  { label: "Kazakhstan", value: "108" },
  { label: "Kenya", value: "109" },
  { label: "Kiribati", value: "110" },
  { label: "Kosovo", value: "111" },
  { label: "Kuwait", value: "112" },
  { label: "Kyrgyzstan", value: "113" },
  { label: "Lao People's Democratic Republic", value: "114" },
  { label: "Latvia", value: "115" },
  { label: "Lebanon", value: "116" },
  { label: "Lesotho", value: "117" },
  { label: "Liberia", value: "118" },
  { label: "Libya", value: "119" },
  { label: "Liechtenstein", value: "120" },
  { label: "Lithuania", value: "121" },
  { label: "Luxembourg", value: "122" },
  { label: "Macau", value: "123" },
  { label: "Macedonia", value: "124" },
  { label: "Madagascar", value: "125" },
  { label: "Malawi", value: "126" },
  { label: "Malaysia", value: "127" },
  { label: "Maldives", value: "128" },
  { label: "Mali", value: "129" },
  { label: "Malta", value: "130" },
  { label: "Marshall Islands", value: "131" },
  { label: "Mauritania", value: "132" },
  { label: "Mauritius", value: "133" },
  { label: "Mayotte", value: "134" },
  { label: "Mexico", value: "135" },
  { label: "Micronesia", value: "136" },
  { label: "Moldova", value: "137" },
  { label: "Monaco", value: "138" },
  { label: "Mongolia", value: "139" },
  { label: "Montenegro", value: "140" },
  { label: "Montserrat", value: "141" },
  { label: "Morocco", value: "142" },
  { label: "Mozambique", value: "143" },
  { label: "Namibia", value: "144" },
  { label: "Nauru", value: "145" },
  { label: "Nepal", value: "146" },
  { label: "Netherlands", value: "147" },
  { label: "Netherlands Antilles", value: "148" },
  { label: "New Caledonia", value: "149" },
  { label: "New Zealand", value: "150" },
  { label: "Nicaragua", value: "151" },
  { label: "Niger", value: "152" },
  { label: "Nigeria", value: "153" },
  { label: "Niue", value: "154" },
  { label: "Norfolk Island", value: "155" },
  { label: "North Korea", value: "156" },
  { label: "Northern Mariana Islands", value: "157" },
  { label: "Norway", value: "158" },
  { label: "Oman", value: "159" },
  { label: "Pakistan", value: "160" },
  { label: "Palau", value: "161" },
  { label: "Panama", value: "162" },
  { label: "Papua New Guinea", value: "163" },
  { label: "Paraguay", value: "164" },
  { label: "Peru", value: "165" },
  { label: "Philippines", value: "166" },
  { label: "Pitcairn Islands", value: "167" },
  { label: "Poland", value: "168" },
  { label: "Portugal", value: "169" },
  { label: "Puerto Rico", value: "170" },
  { label: "Qatar", value: "171" },
  { label: "Republic of the Congo", value: "172" },
  { label: "Romania", value: "173" },
  { label: "Russia", value: "174" },
  { label: "Rwanda", value: "175" },
  { label: "Saint Barthelemy", value: "176" },
  { label: "Saint Helena", value: "177" },
  { label: "Saint Kitts and Nevis", value: "178" },
  { label: "Saint Lucia", value: "179" },
  { label: "Saint Martin", value: "180" },
  { label: "Saint Pierre and Miquelon", value: "181" },
  { label: "Saint Vincent and the Grenadines", value: "182" },
  { label: "Samoa", value: "183" },
  { label: "San Marino", value: "184" },
  { label: "Sao Tome and Principe", value: "185" },
  { label: "Saudi Arabia", value: "186" },
  { label: "Senegal", value: "187" },
  { label: "Serbia", value: "188" },
  { label: "Seychelles", value: "189" },
  { label: "Sierra Leone", value: "190" },
  { label: "Singapore", value: "191" },
  { label: "Slovakia", value: "192" },
  { label: "Slovenia", value: "193" },
  { label: "Solomon Islands", value: "194" },
  { label: "Somalia", value: "195" },
  { label: "South Africa", value: "196" },
  { label: "South Korea", value: "197" },
  { label: "Spain", value: "198" },
  { label: "Sri Lanka", value: "199" },
  { label: "Sudan", value: "200" },
  { label: "Suriname", value: "201" },
  { label: "Swaziland", value: "203" },
  { label: "Sweden", value: "204" },
  { label: "Switzerland", value: "205" },
  { label: "Syrian Arab Republic", value: "206" },
  { label: "Taiwan", value: "207" },
  { label: "Tajikistan", value: "208" },
  { label: "Tanzania", value: "209" },
  { label: "Thailand", value: "210" },
  { label: "Timor-Leste", value: "211" },
  { label: "Togo", value: "212" },
  { label: "Tokelau", value: "213" },
  { label: "Tonga", value: "214" },
  { label: "Trinidad and Tobago", value: "215" },
  { label: "Tunisia", value: "216" },
  { label: "Turkey", value: "217" },
  { label: "Turkmenistan", value: "218" },
  { label: "Turks and Caicos Islands", value: "219" },
  { label: "Tuvalu", value: "220" },
  { label: "Uganda", value: "221" },
  { label: "Ukraine", value: "222" },
  { label: "United Arab Emirates", value: "223" },
  { label: "United Kingdom", value: "224" },
  { label: "United States", value: "225" },
  { label: "Uruguay", value: "226" },
  { label: "US Virgin Islands", value: "227" },
  { label: "Uzbekistan", value: "228" },
  { label: "Vanuatu", value: "229" },
  { label: "Venezuela", value: "230" },
  { label: "Vietnam", value: "231" },
  { label: "Wallis and Futuna", value: "232" },
  { label: "West Bank", value: "233" },
  { label: "Western Sahara", value: "234" },
  { label: "Yemen", value: "235" },
  { label: "Zambia", value: "236" },
  { label: "Zimbabwe", value: "237" },
  { label: "Palestinian Territories", value: "238" }
]

export const genders: Option[] = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' }
];

export {}

const BlankLayout: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  return (
    <div className="blank-layout">
      <div className="container">
        {children}
      </div>
    </div>
  )
}

export default BlankLayout

import { useParams } from 'react-router-dom';
import { useCloseInvoice } from '../hooks/useInvoice';
import { useCancelMembership } from '../hooks/useMemberships';

// this is a temporary page for manual invoice close 

export const TestingPage = () => {
  const { invoiceId, membershipIdToCancel } = useParams()
  const { mutate: closeInvoice, data: closeInvoiceData, isPending: isPendingCloseInvoice, error: closeInvoiceError } = useCloseInvoice(
    (data) => {
      if (data.data.status !== 'success') {
        console.log(data)
      } else {
        console.log('success', data)
      }
    },
    (error) => {
      console.log(error)
    }
  )

  const { mutate: cancelMembership } = useCancelMembership(
    (data) => {
      if (!data.data.success) {
        console.log(`Error canceling previous membership`);
      } else {
        console.log('Membership successfully updated!');
      }
    },
    (error) => {
      console.log({error})
    }
  );

  return (
    <div>
      <p>Invoice ID: {invoiceId}</p>
      <button className="button" onClick={() => closeInvoice(invoiceId || '')}>Close Invoice</button>
      <p>Cancel previous membership: {membershipIdToCancel}</p>
      <button onClick={() => cancelMembership(membershipIdToCancel || '')} className="button">Cancel Membership Immediately</button>
    </div>
  )
}

import { post } from './apiClient';
import { SignInRequest, SignInResponse, SignUpRequest, SignUpResponse, AuthHeaders } from '../types/auth';
import { AxiosResponse } from 'axios';

const extractAuthHeaders = (response: AxiosResponse): AuthHeaders => ({
  'access-token': response.headers['access-token'],
  'token-type': response.headers['token-type'],
  client: response.headers['client'],
  expiry: response.headers['expiry'],
  uid: response.headers['uid'],
  authorization: response.headers['authorization']
});

const handleAuthResponse = async <TResponse, TRequest>(
  url: string,
  requestData: TRequest
): Promise<{ data: TResponse, headers: AuthHeaders }> => {
  try {
    const response = await post<TResponse, TRequest>(url, requestData, {
      validateStatus: (status) => status === 200 || status === 401,
    });

    if (response.status === 401) {
      throw new Error('Unauthorized: Invalid login credentials');
    }
    const authHeaders = extractAuthHeaders(response);

    return { 
      data: response.data,
      headers: authHeaders
    };
  } catch (error: any) {
    if (error.errors?.length || error.error_messages?.length) {
      throw error
    }
    if (error instanceof Error) {
      throw new Error(error.message || 'An error occurred during the request');
    }
    throw new Error('An unexpected error occurred during the request');
  }
};

export const signIn = async (email: string, password: string): Promise<{ data: SignInResponse, headers: AuthHeaders }> => {
  const requestData: SignInRequest = {
    user: { email, password }
  };

  return handleAuthResponse<SignInResponse, SignInRequest>('/api/v1/users/sign_in', requestData);
};

export const signUp = async (
  email: string,
  firstName: string,
  lastName: string,
  boutiqueId: string,
  password: string,
  mobilePhone: string
): Promise<{ data: SignUpResponse, headers: AuthHeaders }> => {
  const requestData: SignUpRequest = {
    user: {
      email,
      firstName,
      lastName,
      boutiqueId,
      password,
      mobilePhone
    }
  };

  return handleAuthResponse<SignUpResponse, SignUpRequest>('/api/v1/users', requestData);
};

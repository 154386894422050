import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/pages/PersonalDetails.module.scss';
import { Input } from '../components/core/Input';
import { Select } from '../components/core/Select';
import { useToast } from '../components/core/ToastManager';
import { useUserProfile, useUpdateUserProfile } from '../hooks/useUserProfile';
import { UserProfileUpdateRequest } from '../types/user';
import Spinner from '../components/core/Spinner';
import { genders, USStates } from '../constants/dropdownObjects';
import { AutoCompleteInput } from '../components/core/AutoCompleteInput';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  gender?: 'male' | 'female' | 'other' | null;
  birthDate?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}


const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  firstName: yup.string()
    .matches(/^[A-Za-z]+$/, 'First name should only contain letters')
    .max(32, 'First name cannot be longer than 32 characters')
    .required('First name is required'),
  lastName: yup.string()
    .matches(/^[A-Za-z]+$/, 'Last name should only contain letters')
    .max(32, 'Last name cannot be longer than 32 characters')
    .required('Last name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  mobilePhone: yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
  gender: yup.string().oneOf(['male', 'female', 'other'] as const).nullable().optional(),
  birthDate: yup.string().matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/, 'Invalid date format. MM/DD/YYYY').nullable().optional(),
  addressLine1: yup.string().nullable().optional(),
  addressLine2: yup.string().nullable().optional(),
  city: yup.string().nullable().optional(),
  state: yup.string().nullable().optional().test('valid-state', 'Invalid state code', (value) => {
    return !value || /^[A-Z]{2}$/.test(value);
  }),
  zip: yup.string().nullable().optional().test('valid-zip', 'Invalid zip code', (value) => {
    return !value || /^[0-9]{5}$/.test(value);
  })
});

const PersonalDetails: React.FC = () => {
  const { data: userProfile, isLoading, error } = useUserProfile();
  const updateUserProfileMutation = useUpdateUserProfile();
  const { addToast } = useToast();

  const { register, handleSubmit, control, formState: { errors, isDirty } } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: userProfile?.data || {
      firstName: '',
      lastName: '',
      email: '',
      mobilePhone: '',
      gender: null,
      birthDate: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: ''
    },
    values: userProfile?.data
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const changedFields = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== userProfile?.data[key as keyof FormData]) {
          acc[key as keyof UserProfileUpdateRequest] = value;
        }
        return acc;
      }, {} as UserProfileUpdateRequest);
  
      if (Object.keys(changedFields).length > 0) {
        await updateUserProfileMutation.mutateAsync(changedFields);
        addToast('Details successfully updated!', 'success', 3500);
      } else {
        addToast('No changes to update', 'warning', 3500);
      }
    } catch (error) {
      addToast('Failed to update details. Please try again.', 'error', 3500);
    }
  };

  if (isLoading) return <Spinner />;
  if (error) return <div>Error loading user profile</div>;

  return (
    <div className={styles.personalDetails}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>

        <Input id="firstName" label="First Name" inputProps={register('firstName')} error={errors.firstName?.message} />
        <Input id="lastName" label="Last Name" inputProps={register('lastName')} error={errors.lastName?.message} />
        <Input id="email" label="Email Address" inputProps={{ ...register('email'), type: 'email' }} error={errors.email?.message} />
        <Input id="phoneNumber" label="Phone Number" inputProps={register('mobilePhone')} error={errors.mobilePhone?.message} />
        
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <Select
              id="gender"
              label="Gender"
              selectProps={{ ...field, value: field.value ?? '' }}
              error={errors.gender?.message}
            >
              {genders.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          )}
        />

        <Input id="birthday" label="Birthday" inputProps={{ ...register('birthDate'), type: 'text', placeholder: "MM/DD/YYYY" }} error={errors.birthDate?.message} />
        <Input id="address" label="Address" inputProps={register('addressLine1')} error={errors.addressLine1?.message} />
        <Input id="addressLine2" label="Apartment, Suite, Etc." inputProps={register('addressLine2')} error={errors.addressLine2?.message} />
        <Input id="city" label="City" inputProps={register('city')} error={errors.city?.message} />
        <div className={styles.fieldGroup}>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <AutoCompleteInput
                id="state"
                label="State"
                inputProps={{ ...field, value: field.value || '' }}
                error={errors.state?.message}
                options={USStates || []}
                value={field.value || ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
          <Input id="zipCode" label="Zip Code" inputProps={register('zip')} error={errors.zip?.message} />
        </div>
        <button 
          className={`button ${styles.button} ${!isDirty || updateUserProfileMutation.status === 'pending' ? "disabled" : ""}`} 
          disabled={!isDirty || updateUserProfileMutation.status === 'pending'} 
          type="submit"
          >
          {updateUserProfileMutation.status === 'pending' ? 'Updating...' : 'Update Details'}
        </button>
      </form>
    </div>
  );
};

export default PersonalDetails;

import styles from '../../styles/components/Spinner.module.scss';

interface SpinnerProps {
  size?: number; // Size of the spinner in pixels
  color?: string; 
}

const Spinner: React.FC<SpinnerProps> = ({ size = 40, color = '#404D6A' }) => {
  return (
    <div
      className={styles.spinner}
      style={{
        width: size,
        height: size,
        borderColor: `${color} ${color} ${color} #d8d9d9`,
      }}
    />
  );
};

export default Spinner;

import { useEffect } from 'react'
import Spinner from '../components/core/Spinner'

export const PurchaseSuccessRedirect = () => {
  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'processComplete',
        message: 'Your membership was created successfully!',
      },
      '*'
    );
  })
  return (
    <div>
      <h1 style={{textAlign: "center"}}>Redirecting...</h1>
      <Spinner />
    </div>
  )
}

export default PurchaseSuccessRedirect;
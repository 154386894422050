// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermsModal_termsModalContent__2u6GJ {
  padding: 0 1rem;
}
.TermsModal_termsModalContent__2u6GJ h2 {
  font-weight: 400;
}
.TermsModal_termsModalContent__2u6GJ p {
  line-height: 24px;
}
.TermsModal_termsModalContent__2u6GJ .TermsModal_termsModalButton__g8UV7 {
  margin-top: 3rem;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/TermsModal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ;AACE;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;AACJ","sourcesContent":[".termsModalContent {\n  padding: 0 1rem;\n  h2 {\n    font-weight: 400;\n  }\n  p {\n    line-height: 24px;\n  }\n\n  .termsModalButton {\n    margin-top: 3rem;\n    text-align: center;\n    width: 100%;\n    text-transform: uppercase;\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsModalContent": `TermsModal_termsModalContent__2u6GJ`,
	"termsModalButton": `TermsModal_termsModalButton__g8UV7`
};
export default ___CSS_LOADER_EXPORT___;

import styles from '../styles/pages/Memberships.module.scss'
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { TermsModal } from '../components/TermsModal';
import MembershipCredits from '../components/core/MembershipCredits';
import StarsIcon from '../assets/icons/stars-icon.svg';
import PiggyBankIcon from '../assets/icons/piggy-bank-icon.svg';
import ShoppingBagIcon from '../assets/icons/shopping-bag-icon.svg';
import ArrowsCircleIcon from '../assets/icons/arrows-circle-icon.svg';
import { groupMembershipsByTitle } from '../utils/groupMemberships';

const MOCK_MEMBERSHIPS = [
  { title: 'The Pampered Ritual', 
    credits: 4,
    creditType: 'The Pampered Ritual Credits',
    location: 'West Hollywood, CA',
    description: '50 Minutes | Once per month',
    renewPolicy: 'Auto-renews June 29, 2024 for $xxx',
    membershipCredits: [
      { title: 'The Pampered Ritual', amount: 4 }
    ]
   },
   { title: 'The Sanctuary Ritual', 
    credits: 4,
    creditType: 'The Sanctuary Ritual Credits',
    location: 'Santa Monica, CA',
    description: '80 Minutes | Once per month',
    renewPolicy: 'Auto-renews June 29, 2024 for $xxx',
    membershipCredits: [
      { title: 'The Sanctuary Ritual', amount: 4 }
    ]
   },
]

const MOCK_NEW_MEMBERSHIPS = [
  { 
    id: "1",
    title: 'The Sanctuary Ritual',
    location: 'West Hollywood, CA',
    frequency: 'One 50 minute massage',
    duration: '50 Minutes',
    price: '$80',
    nonMemberPrice: '$155',
    active: true,
  },
  { 
    id: "2",
    title: 'The Sanctuary Ritual',
    location: 'West Hollywood, CA',
    frequency: 'Two 50 minute massages',
    duration: '50 Minutes',
    price: '$120',
    nonMemberPrice: '$195',
    active: false,
  },
  { 
    id: "3",
    title: 'The Pampered Ritual',
    location: 'West Hollywood, CA',
    frequency: 'One 80 minute massage',
    duration: '80 Minutes',
    price: '$140',
    nonMemberPrice: '$195',
    active: false,
  },
  { 
    id: "4",
    title: 'The Pampered Ritual',
    location: 'West Hollywood, CA',
    frequency: 'Two 80 minute massages',
    duration: '80 Minutes',
    price: '$220',
    nonMemberPrice: '$260',
    active: false,
  },
];

const MembershipBenefits = [
  { title: 'Friends & family member rate for anyone new to The NOW', icon: StarsIcon },
  { title: '10% off in-store purchases on nourish, scent & room by the NOW Products', icon: ShoppingBagIcon },
  { title: 'Savings on monthly massages', icon: PiggyBankIcon },
  { title: 'Roll-over unused credits', icon: ArrowsCircleIcon },
]

export const Memberships = () => {
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [searchParams] = useSearchParams();
  const isFilled = searchParams.get('filled') === 'true';
  const groupedMemberships = groupMembershipsByTitle(MOCK_NEW_MEMBERSHIPS);
  const priceToNumber = (price: string): number => parseFloat(price.replace('$', ''));
  
  if (isFilled) {
    return (
      <div className={styles.memberships}>
        {MOCK_MEMBERSHIPS.map((membership, index) => (
          <div className={styles.membershipCard} key={index}>
            <h2>{membership.title}</h2>
            <p className={styles.description}>{membership.description}</p>
            <p className={styles.location}>{membership.location}</p>
            <MembershipCredits membershipCredits={membership.membershipCredits} />
            <p>{membership.renewPolicy}</p>
            <p className={styles.terms}>Membership <button className="button__link" onClick={() => setShowTermsModal(true)}>Terms & Conditions</button></p>
            <Link to={`/settings/memberships/update/128393`} className={styles.cardButton}>Change Membership</Link>
            <button className={styles.cardButton}>Pause Membership</button>
            <Link to={`/settings/memberships/cancel/128393`} className={styles.cardButton}>Cancel Membership</Link>
          </div>
        ))}
        <TermsModal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)} />
      </div>
  )} else {
    return (
      <div className={styles.noMemberships}>
        <div className={styles.header}>
          <h3 className={styles.benefitTitle}>Save up to $100 on monthly massages</h3>
          <p>Make massage a part of your monthly ritual. No long-term commitments, cancel any time with 30 days notice.</p>
        </div>
        <div className={styles.membershipBenefits}>
          {MembershipBenefits.map((benefit, index) => (
          <div key={index} className={styles.membershipBenefit}>
            <img src={benefit.icon} />
            <p>{benefit.title}</p>
          </div>
          ))}
        </div>
        <div className={styles.selectMembership}>
        {Object.entries(groupedMemberships).map(([title, memberships]) => (
          <div key={title} className={styles.membershipColumn}>
            <h3>{title} ({memberships[0].duration})</h3>
            {memberships.map((membership) => (
              <div
                key={membership.id}
                className={styles.membershipCard}
              >
                <div className={styles.membershipContentLeft}>
                  <p className={styles.membershipFrequency}>{membership.frequency}</p>
                  <p>per month</p>
                  <button className={`button`}>Choose</button>
                </div>
                <div className={styles.membershipContentRight}>
                  <div className={styles.price}>
                    <div className={styles.membershipPrice}>{membership.price}</div>
                    <div className={styles.nonMemberPrice}>per month</div>
                  </div>
                  <p className={styles.discount}>${priceToNumber(membership.nonMemberPrice) - priceToNumber(membership.price)} SAVINGS</p>
                </div>
              </div>
            ))}
          </div>
        ))}
        </div>
      </div>
  )}
}

export default Memberships
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/pages/UserMemberships.module.scss';
import { useUserMemberships } from '../hooks/useUserProfile';
import Modal from '../components/core/Modal';
import MembershipCredits from '../components/core/MembershipCredits';
import Memberships from './Memberships';
import { PauseMembershipModal } from '../components/PauseMembershipModal';
import Spinner from '../components/core/Spinner';
import { DateTime } from 'luxon';
import { useToast } from '../components/core/ToastManager';
import { UserMembership } from '../types/memberships';

export const UserMemberships = () => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);

  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data: userMemberships, isLoading: isLoadingUserMemberships, error: userMembershipsError } = useUserMemberships(true);

  const hasUserMemberships = userMemberships?.membershipGroups ? userMemberships.membershipGroups.length > 0 : false;
  const isCancellable = userMemberships?.cancellable;

  if (isLoadingUserMemberships) return <Spinner />;
  if (!hasUserMemberships) return <Memberships />;
  
  if (userMembershipsError) {
    return <div className={styles.error}>Error loading memberships. Please try again later.</div>;
  }

  function isMembershipExpired(userMembership: UserMembership) {
    const terminationDate = userMembership.terminationDate ? DateTime.fromISO(userMembership.terminationDate).startOf('day') : null;
    return terminationDate && terminationDate <= DateTime.now().startOf('day');
  }

  const activeMemberships = userMemberships?.membershipGroups
    .flatMap((group) => group.userMemberships.map((userMembership) => ({ ...userMembership, groupName: group.name })))
    .filter((membership) => !isMembershipExpired(membership));

  if (activeMemberships && activeMemberships.length) {
    return (
      <div className={styles.memberships}>
        {activeMemberships.map((userMembership, index) => {
          const membership = userMembership.membership;
          const creditsName = userMembership.groupName;
          const cancellationLink = `/settings/memberships/cancel/${membership.boutique.id}/${membership.zenotiId}/${userMembership.zenotiId}`;
          const isCancelled = ["cancelled", "closed", "expired"].includes(userMembership.status);
          const isFrozen = userMembership.status === "frozen";
          const terminationDate = userMembership.terminationDate 
            ? DateTime.fromISO(userMembership.terminationDate).toFormat("MMMM dd, yyyy")
            : null;

          const handleCancel = () => {
            if (isCancellable) navigate(cancellationLink);
            else addToast('Membership cannot be cancelled online. Please contact your boutique for assistance!', 'error', 4000);
          };

          return (
            <div className={styles.membershipCard} key={index}>
              <h2>{membership.name}</h2>
              <p className={styles.description}>{membership.durationInMinutes} minutes | {membership.frequencyPerMonth} per month</p>
              <p className={styles.location}>{membership.boutique.city}, {membership.boutique.state}</p>
              <MembershipCredits name={creditsName} amount={membership.creditBalance || 0} />
              
              {membership.nextCollectionDate && !isCancelled && !isFrozen && (
                <p>Auto-renews {DateTime.fromISO(membership.nextCollectionDate).toFormat("MMMM dd, yyyy")} for ${membership.nextCollectionAmount}</p>
              )}

              {isFrozen && userMembership.unfreezeDate && (
                <p>Your membership is paused until {DateTime.fromISO(userMembership.unfreezeDate).toFormat("MMMM dd, yyyy")}</p>
              )}

              {isCancelled && terminationDate && (
                <ul className={styles.terminationConditions}>
                  <li>Your last billing cycle will be charged on {terminationDate} for ${membership.nextCollectionAmount}</li>
                  <li>You will have access to your membership until the end of your billing period on {terminationDate}.</li>
                  <li>Your credits will be valid for 3 months after your membership is cancelled.</li>
                </ul>
              )}

              {membership.termsAndConditions && !isCancelled && (
                <p className={styles.terms}>
                  Membership <button className="button__link" onClick={() => setShowTermsModal(true)}>Terms & Conditions</button>
                </p>
              )}

              {isCancelled ? (
                <Link to="/memberships" className={styles.cardButton}>Purchase a New Membership</Link>
              ) : isFrozen ? (
                <button onClick={() => setShowPauseModal(true)} className={styles.cardButton}>Resume Membership</button>
              ) : (
                <>
                  <Link to={`/settings/memberships/update/${membership.boutique.id}/${membership.zenotiId}/${userMembership.zenotiId}`} className={styles.cardButton}>Change Membership</Link>
                  <button onClick={() => setShowPauseModal(true)} className={styles.cardButton}>Pause Membership</button>
                  <button onClick={handleCancel} className={styles.cardButton}>Cancel Membership</button>
                </>
              )}

              <Modal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)}>
                <p>{membership.termsAndConditions}</p>
              </Modal>
              <Modal className={styles.pauseModal} isOpen={showPauseModal} onClose={() => setShowPauseModal(false)} hideCloseButton >
                <PauseMembershipModal userMembership={userMembership} onClose={() => setShowPauseModal(false)} />
              </Modal>
            </div>
          );
        })}
      </div>
    );
  }
  
  return <Memberships />;
};

export default UserMemberships;

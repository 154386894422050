// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputFeedback_inputFeedback__98u-8 {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 3px;
}
.InputFeedback_inputFeedback__98u-8 .InputFeedback_feedbackText__2HwMK {
  font-size: 12px;
  margin: 0;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/InputFeedback.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;EACA,SAAA;EACA,gBAAA;AACJ","sourcesContent":[".inputFeedback {\n  margin-top: 0.5rem;\n  display: flex;\n  align-items: center;\n  gap: 3px;\n\n  .feedbackText {\n    font-size: 12px;\n    margin: 0;\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFeedback": `InputFeedback_inputFeedback__98u-8`,
	"feedbackText": `InputFeedback_feedbackText__2HwMK`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useRef, useEffect } from 'react';
import { InputHTMLAttributes } from 'react';
import styles from '../../styles/components/Input.module.scss';
import ArrowIcon from '../../assets/icons/arrow-down.svg';
import InputFeedback from './InputFeedback';

interface Option {
    value: string;
    label: string;
}

interface AutoCompleteProps {
    id: string;
    label: string;
    className?: string;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    error?: string;
    options: Option[];
    value: string;
    onChange: (value: string) => void;
    onBlur: () => void;
}

export const AutoCompleteInput: React.FC<AutoCompleteProps> = ({
    id,
    label,
    className,
    inputProps,
    error,
    options,
    value,
    onChange,
    onBlur
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setFilteredOptions(options);
        const selectedOption = options.find(option => option.value === value);
        setInputValue(selectedOption ? selectedOption.label : '');
    }, [options, value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        setInputValue(searchValue);
        if (searchValue) {
            setFilteredOptions(
                options.filter((option) =>
                    option.label.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        } else {
            setFilteredOptions(options);
        }
        setIsOpen(true);
    };

    const handleOptionClick = (option: Option) => {
        onChange(option.value);
        setInputValue(option.label);
        setIsOpen(false);
        inputRef.current?.blur();
    };

    const handleFocus = () => {
        setIsOpen(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsOpen(false);
            onBlur();
        }
    };

    return (
        <div className={`${styles.input} ${className} ${error ? styles.entryErrorAC : ''}`} onBlur={handleBlur}>
            <input
                id={id}
                {...inputProps}
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                aria-invalid={!!error}
                autoComplete="off"
                placeholder=' '
            />
            <label htmlFor={id}>{label}</label>
            <img src={ArrowIcon} className={styles.arrowIcon} alt="arrow down" onClick={() => setIsOpen(!isOpen)} />
            {error && <InputFeedback message={error} variant='error' extraClasses={styles.error} />}
            {isOpen && filteredOptions.length > 0 && (
                <ul className={styles.suggestionsList}>
                    {filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            className={styles.suggestion}
                            onClick={() => handleOptionClick(option)}
                            tabIndex={0}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

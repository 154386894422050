import styles from '../../styles/components/Switch.module.scss';

export interface SwitchProps {
  id: string;
  isOn: boolean;
  handleToggle: () => void;
  disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({ isOn, handleToggle, id, disabled }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={styles.switchCheckbox}
        id={id}
        type="checkbox"
        disabled={disabled}
      />
      <label
        style={{ background: isOn ? '#404D6A' : '', cursor: disabled ? 'not-allowed' : 'pointer' }}
        className={styles.switchLabel}
        htmlFor={id}
      >
        <span className={styles.switchButton} />
      </label>
    </>
  );
};

export default Switch;

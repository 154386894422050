// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_spinner__8o6h- {
  border: 4px solid;
  border-radius: 50%;
  border-top: 4px solid;
  border-right: 4px solid #000;
  width: 40px;
  height: 40px;
  animation: Spinner_spin__AHwtU 1s linear infinite;
  margin: 0 auto;
}
@keyframes Spinner_spin__AHwtU {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/components/Spinner.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,4BAAA;EACA,WAAA;EACA,YAAA;EACA,iDAAA;EACA,cAAA;AACF;AACE;EACE;IACE,uBAAA;EACJ;EACE;IACE,yBAAA;EACJ;AACF","sourcesContent":[".spinner {\n  border: 4px solid;\n  border-radius: 50%;\n  border-top: 4px solid;\n  border-right: 4px solid #000;\n  width: 40px;\n  height: 40px;\n  animation: spin 1s linear infinite;\n  margin: 0 auto;\n\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Spinner_spinner__8o6h-`,
	"spin": `Spinner_spin__AHwtU`
};
export default ___CSS_LOADER_EXPORT___;

import { createBrowserRouter, Outlet } from 'react-router-dom';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import GuestLayout from './layouts/GuestLayout';
import BlankLayout from './layouts/BlankLayout';
import PrivateRoute from './components/auth/PrivateRoute';
import NotificationsSettings from './components/NotificationsSettings';
// Pages
import Dashboard from './pages/Dashboard';
import AccountSettings from './pages/AccountSettings';
import Memberships from './pages/Memberships';
import UpdatePassword from './pages/UpdatePassword';
import PersonalDetails from './pages/PersonalDetails';
import BillingDetails from './pages/BillingDetails';
import UpdateMembership from './pages/UpdateMembership';
import Login from './pages/Login';
import CreateAccount from './pages/CreateAccount';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CancelMembership from './pages/CancelMembership';
import Appointments from './pages/Appointments';
import UpcomingAppointments from './pages/UpcomingAppointments';
import { AddCardSuccessRedirect } from './pages/AddCardSuccessRedirect';

const authenticatedRoutes = [
  { path: '/', element: <Dashboard /> },
  { path: '/dashboard', element: <Dashboard /> },
  { 
    path: '/appointments',
    element: <Appointments />,
    children: [
      { index: true, element: <UpcomingAppointments /> },
      { path: 'upcoming', element: <UpcomingAppointments /> },
      // { path: 'past', element: <PastAppointments /> },
    ]},
  { 
    path: '/settings', 
    element: <AccountSettings />,
    children: [
      { index: true, element: <PersonalDetails /> },
      { path: 'notifications', element: <NotificationsSettings /> },
      { path: 'password', element: <UpdatePassword /> },
      { path: 'personal-details', element: <PersonalDetails /> },
      { path: 'billing', element: <BillingDetails /> },
      { path: 'memberships', element: <Memberships /> },
    ]
  },
  { path: '/settings/memberships/update/:id', element: <UpdateMembership /> },
  { path: '/settings/memberships/cancel/:id', element: <CancelMembership /> },
];

const guestRoutes = [
  { path: 'login', element: <Login /> },
  { path: 'register', element: <CreateAccount /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  { path: 'reset-password', element: <ResetPassword /> },
];

const blankRoutes = [
  { path: 'add-card-success', element: <AddCardSuccessRedirect /> },
]

export const router = createBrowserRouter([
  {
    element: (
      <PrivateRoute>
        <AuthenticatedLayout>
          <Outlet />
        </AuthenticatedLayout>
      </PrivateRoute>
    ),
    children: authenticatedRoutes,
  },
  {
    element: (
      <GuestLayout>
        <Outlet />
      </GuestLayout>
    ),
    children: guestRoutes,
  },
  {
    element: (
      <BlankLayout>
        <Outlet />
      </BlankLayout>
    ),
    children: blankRoutes,
  },
]);
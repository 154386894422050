import { post, del } from './apiClient';
import { PaymentProfileRequest, PaymentProfileResponse, DeletePaymentProfileResponse  } from '../types/payment';


export const createPaymentProfile = async (
  paymentData: PaymentProfileRequest
): Promise<PaymentProfileResponse> => {
  const response = await post<PaymentProfileResponse, PaymentProfileRequest>('/api/v1/user/payment_profiles', paymentData);
  return response.data;
};

export const deletePaymentProfile = async (accountId: string): Promise<DeletePaymentProfileResponse> => {
  const response = await del<DeletePaymentProfileResponse, { account_id: string }>('/api/v1/user/payment_profiles', { account_id: accountId });
  return response.data;
};